import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Accounts/SupplierBill';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

const SupplierBill = () => {
    return (


        <div>
            <div class="modal fade" id="view-discount" tabindex="-1">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Discount per Case</h6>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="adddiscount-popup">
                                <form>
                                    <table className="table table-bordered dataTable">
                                        <tbody>
                                            <tr>
                                                <td>Retro</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"

                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Prompt Payment</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="promptPayment"

                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cost to Serve</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"

                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Off Invoice</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"

                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Beds & Leds</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"

                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Sugro Retro</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"

                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Supplier Bill</span> </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left', width: '100%' }}>

                                        <div className="newsalesorderform">
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Select Supplier * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control search-area-big" id="addr1" />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Bill Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" />
                                                        </div>

                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Bill Due Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Bill No * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className="col-6 col-lg-6 col-sm-6">

                                                    <div>

                                                        <div className="row mb-3">
                                                            <div className="col-4 col-lg-3 col-sm-3">
                                                                <label htmlFor="desc" className="col-form-label">Select P.O. * </label>
                                                            </div>
                                                            <div className="col-8 col-lg-9 col-sm-9">
                                                                <input type="text" className="form-control search-area-big" id="addr1" />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-4 col-lg-3 col-sm-3">
                                                                <label htmlFor="desc" className="col-form-label">Job Ref *</label>
                                                            </div>
                                                            <div className="col-8 col-lg-9 col-sm-9">
                                                                <input type="text" name="addr2" className="form-control" />
                                                            </div>
                                                        </div>
                                                        {/* <div className="row mb-3">
                                                            <div className="col-4 col-lg-3 col-sm-3">
                                                                <label htmlFor="desc" className="col-form-label">VAT %</label>
                                                            </div>
                                                            <div className="col-8 col-lg-9 col-sm-9">
                                                                <select name="delivery_id" class="form-select orgselect">
                                                                    <option>Select VAT</option>
                                                                    <option>20 %</option>
                                                                    <option>0 %</option>
                                                                </select>
                                                            </div>


                                                        </div> */}


                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <h5>Confirm Quantity</h5>
                                        <div className="verical-scrl-wrapper common-table supplierbill-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Expiry & Batch</th>
                                                        <th>Description</th>
                                                        <th>PK</th>
                                                        <th>Size</th>
                                                        <th>Discount</th>
                                                        <th>Order Qty</th>

                                                        <th>Received Qty (GRN)</th>
                                                        <th>Bill Qty</th>
                                                        <th>Pending</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>03/01/2025</td>
                                                        <td>Skittles Crazy Sours</td>
                                                        <td>36</td>
                                                        <td>45 GMS</td>
                                                        <td>

                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal" data-bs-target="#view-discount"
                                                            >
                                                                100
                                                            </a>
                                                        </td>
                                                        <td>1000</td>

                                                        <td>900</td>
                                                        <td><input type="text" class="form-control" placeholder="900" /></td>
                                                        <td>100</td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>


                                        <h5>Accounting Code</h5>
                                        <div className="verical-scrl-wrapper common-table accountcode-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>Description</th>
                                                        <th>Amount</th>
                                                        <th>Pending</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>

                                                    <tr>
                                                        <td><a href='#'>SKI010</a></td>
                                                        <td>Bank HSBC Bank 31513621</td>
                                                        <td>Cash and Bank</td>
                                                        <td><input type="text" class="form-control" placeholder="ABC" /></td>
                                                        <td><input type="text" class="form-control" placeholder="90" /></td>
                                                        <td><a href="#"><i class="fa fa-trash"></i></a></td>

                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>






                                        <div className="purchaseform">


                                            <div className="row">

                                                <div className="col-6 col-lg-10 col-sm-10">

                                                    <div className="row mb-3">
                                                        <div className="col-6 col-lg-1 col-sm-1">
                                                            <label htmlFor="desc" className="col-form-label">Net Amount </label>
                                                        </div>
                                                        <div className="col-6 col-lg-2 col-sm-2">
                                                            <input type="text" className="form-control" id="addr1" />
                                                        </div>

                                                        <div className="col-6 col-lg-1 col-sm-1">
                                                            <label htmlFor="desc" className="col-form-label">Discount </label>
                                                        </div>
                                                        <div className="col-6 col-lg-2 col-sm-2">
                                                            <input type="text" className="form-control" id="addr2" />
                                                        </div>

                                                        <div className="col-6 col-lg-1 col-sm-1">
                                                            <label htmlFor="desc" className="col-form-label">VAT </label>
                                                        </div>
                                                        <div className="col-6 col-lg-2 col-sm-2">
                                                            <input type="text" className="form-control" id="addr2" />
                                                        </div>

                                                        <div className="col-6 col-lg-1 col-sm-1">
                                                            <label htmlFor="desc" className="col-form-label">Total </label>
                                                        </div>
                                                        <div className="col-6 col-lg-2 col-sm-2">
                                                            <input type="text" className="form-control" id="addr2" />
                                                        </div>

                                                    </div>


                                                </div>

                                                <div className="col-6 col-lg-2 col-sm-2">
                                                    <div style={{ float: "right", width: "100%" }}>
                                                        <a href="#" className="create-catalogue-btn"><i className='fa fa-plus'></i>&nbsp;Accounting Code</a>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>



                                        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                        <div class="btn-group bouble-btn" role="group" aria-label="Basic example">
                                            <button type="button" class="saveclose">Save & Close</button>

                                            <button type="button" class="savenew">Save & New</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>



            <Footer />
        </div>
    );
};

export default SupplierBill; 
