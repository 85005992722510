import React, { useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';

const AddSupplierModal = () => {
    const modalRef = useRef(null);

    const validationSchema = Yup.object({
        name: Yup.string().required("Supplier Name is required"),
        address: Yup.string().required("Address Line 1 is required"),
        postcode: Yup.string().required("Postcode is required"),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
        bank_name: Yup.string().required("Bank Name is required"),
        bank_account_number: Yup.string().required("Account Number is required"),
        iban: Yup.string().required("IBAN is required"),
        sort_code: Yup.string().required("Sort Code is required"),
    });

    const submitNewSupplierForm = (values) => {
        console.log(values);
        // Handle form submission logic here
    };

    return (
        <div className="modal fade" id="add-supplier" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add New Supplier</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="add-product-popup">
                            <Formik
                                initialValues={{
                                    name: '',
                                    address: '',
                                    postcode: '',
                                    country: '',
                                    city: '',
                                    vat: '',
                                    status: true,
                                    currency: 'GBP',
                                    contact_person_name: '',
                                    contact_person_mobile: '',
                                    contact_person_email: '',
                                    bank_name: '',
                                    bank_account_number: '',
                                    iban: '',
                                    sort_code: '',
                                    branch_bic: '',
                                    bank_bic: '',
                                    payment_type: '1',
                                    direct_debit: '',
                                    no_of_days_from_inv: '',
                                    credit_limit: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={submitNewSupplierForm}
                            >
                                {() => (
                                    <Form id="addSupplierForm">
                                        <h4>Supplier Details</h4>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="name" className="col-sm-3 col-form-label">Supplier Name *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="name" />
                                                <ErrorMessage name="name" component="div" className="text-danger" />
                                            </div>
                                            <label htmlFor="address" className="col-sm-3 col-form-label">Address Line 1 *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="address" />
                                                <ErrorMessage name="address" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="postcode" className="col-sm-3 col-form-label">Postcode *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="postcode" />
                                                <ErrorMessage name="postcode" component="div" className="text-danger" />
                                            </div>
                                            <label htmlFor="address2" className="col-sm-3 col-form-label">Address Line 2</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="address2" />
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="country" className="col-sm-3 col-form-label">Country *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="country" />
                                                <ErrorMessage name="country" component="div" className="text-danger" />
                                            </div>
                                            <label htmlFor="city" className="col-sm-3 col-form-label">City *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="city" />
                                                <ErrorMessage name="city" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="vat" className="col-sm-3 col-form-label">VAT Number</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="vat" />
                                            </div>
                                            <label htmlFor="status" className="col-sm-3 col-form-label">Status</label>
                                            <div className="col-sm-3">
                                                <label className="switch">
                                                    <Field type="checkbox" className="switch-input" name="status" defaultChecked />
                                                    <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                                                    <span className="switch-handle"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="currency" className="col-sm-3 col-form-label">Currency *</label>
                                            <div className="col-sm-3">
                                                <Field as="select" name="currency" className="form-select select">
                                                    <option value="GBP">GBP</option>
                                                    <option value="USD">USD</option>
                                                    <option value="EUR">EUR</option>
                                                </Field>
                                            </div>
                                        </div>

                                        <h4>Contact Person</h4>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="contact_person_name" className="col-sm-3 col-form-label">Contact Person Name</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="contact_person_name" />
                                            </div>
                                            <label htmlFor="contact_person_mobile" className="col-sm-3 col-form-label">Mobile</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="contact_person_mobile" />
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="contact_person_email" className="col-sm-3 col-form-label">Email</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="contact_person_email" />
                                            </div>
                                        </div>

                                        <h4>Banking Details</h4>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="bank_name" className="col-sm-3 col-form-label">Bank Name *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="bank_name" />
                                                <ErrorMessage name="bank_name" component="div" className="text-danger" />
                                            </div>
                                            <label htmlFor="bank_account_number" className="col-sm-3 col-form-label">Account Number *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="bank_account_number" />
                                                <ErrorMessage name="bank_account_number" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="iban" className="col-sm-3 col-form-label">IBAN *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="iban" />
                                                <ErrorMessage name="iban" component="div" className="text-danger" />
                                            </div>
                                            <label htmlFor="sort_code" className="col-sm-3 col-form-label">Sort Code *</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="sort_code" />
                                                <ErrorMessage name="sort_code" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="branch_bic" className="col-sm-3 col-form-label">Branch BIC</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="branch_bic" />
                                            </div>
                                            <label htmlFor="bank_bic" className="col-sm-3 col-form-label">Bank BIC</label>
                                            <div className="col-sm-3">
                                                <Field type="text" className="form-control" name="bank_bic" />
                                            </div>
                                        </div>

                                        <h4>Payment Terms</h4>
                                        <div className="row mb-3 padd-l-13px">
                                            <label htmlFor="payment_type" className="col-sm-3 col-form-label">Payment Type</label>
                                            <div className="col-sm-9">
                                                <div className="paymenttypearea">
                                                    <ul>
                                                        <li>
                                                            <Field type="radio" name="payment_type" value="1" />
                                                            <label className="form-check-label" style={{ width: "170px" }}>Direct Debit</label>
                                                            <Field type="text" className="form-control" name="direct_debit" />
                                                        </li>
                                                        <li>
                                                            <Field type="radio" name="payment_type" value="2" />
                                                            <label className="form-check-label" style={{ width: "170px" }}>No. of days from the INV</label>
                                                            <Field type="text" className="form-control" name="no_of_days_from_inv" />
                                                        </li>
                                                        <li>
                                                            <Field type="radio" name="payment_type" value="3" />
                                                            <label className="form-check-label" style={{ width: "170px" }}>Credit Limit</label>
                                                            <Field type="text" className="form-control" name="credit_limit" />
                                                        </li>
                                                        <li>
                                                            <Field type="radio" name="payment_type" value="4" />
                                                            <label className="form-check-label" style={{ width: "170px" }}>At Sight / On PFI</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row padd-l-13px">
                                            <div className="col-sm-12">
                                                <button type="submit" className="popup-innerbtn-common right">Save</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSupplierModal;
