import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $, { event } from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';



const UserList = () => {
  const accessToken = localStorage.getItem('accessToken');

    const [showPasswordInput , setShowPasswordInput] = useState(false);
    const modalRef = useRef(null);
    const editModalRef = useRef(null);    
    const tableRef = useRef(null); // Reference to the table element
    const [roleData, setRoleData] = useState([]); 

      const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    mobile: Yup.string().length(10, 'Mobile number must be 10 digits'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required'),
  });
  
  const initializeDataTable = () => {
    const $table = $(tableRef.current);
    const searchValue = $('#searchValue').val();
    // Destroy existing DataTable instance if it exists
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }

    let i = 0;
    $table.DataTable({
        processing: false,
        serverSide: true,
        ajax: {
            url: `${config.api_url}/ws-get-user-list`,
            type: 'POST',
            headers: {
                        'Authorization': `Bearer ${accessToken}`
                     },
            data: function (d) {
                return $.extend({}, d, {
                    draw: d.draw,
                    start: d.start,
                    length: d.length,
                    searchValue : searchValue
                });
            },
            dataSrc: function (json) {
                return json.user;
            }
        },
        columns: [
                      { data: 'code', width: "5%" },
                      { data: 'email',width: "12%"}, 
                      { data: 'name' },
                      { data: 'mobile', width: "10%" },
                      { data: 'designation', width: "10%" },
                      { data: 'department', width: "10%" },
                      { data: 'role', width: "7%" },
                      {
                          data: 'status',
                          width: "6%",
                          render: (data) => data === '1' ? 'Active' : 'Inactive'
                      },
                      {
                        data: null,
                        title: 'Action',
                        width: "6%", 
                        render: (data, type, row) => `
                            <a href="#" class=" editbtn" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#edit_user">
                                <i class="fa fa-edit"></i> 
                            </a>`
                      }
                  ],

        columnDefs: [
          { orderable: false, targets: '_all' }
      ],
        pageLength: 10,
    });


    // Event delegation for edit and view actions
    $table.on('click', '.editbtn', function() {
        const id = $(this).data('id');
        handleEditClick(id);
    });

};



  useEffect(() => {
      initializeDataTable();
      fetchRoleData();
  
      return () => {
          if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
              $(tableRef.current).DataTable().destroy();
          }
      };
  }, []);
  
      const fetchRoleData = async () => {
        const apiUrl = `${config.api_url}/ws-get-role`;
          if (!accessToken) {
              window.location.href = '/login';
              return;
          }
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
          },
          });
          const data = await response.json();
          setRoleData(data.role);
        } catch (error) {
          console.error("Error fetching role data:", error);
        }
      };

      const handleAddUser = async () => {
      //  e.preventDefault();
        try {
            const formElement = document.getElementById("addUserForm");
            const formData = new FormData(formElement);
    
            // Convert checkbox value to 1 or 0
            formData.set("status", formData.get("status") === "on" ? 1 : 0);
    
            // Make the API request using axios
            const response = await axios.post(
                `${config.api_url}/ws-add-user`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );
    
            // Handle the response
            if (response.data.success) {

               if (modalRef.current) {
                        const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
                        if (bsModal) bsModal.hide();
                    }
    
                Swal.fire({
                    title: "Success!",
                    text: "User added successfully.",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                }).then(() => {
                    // Close the modal if it's open
                    if (modalRef.current) {
                        const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
                        if (bsModal) bsModal.hide();
                    }
    
                    // Refresh the DataTable
                    initializeDataTable();
    
                    // Reset the form
                    formElement.reset();
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    text: response.data.message || "An error occurred while adding a user.",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error("Request Error:", error);
            Swal.fire({
                title: "Error!",
                text: "An error occurred while adding a user.",
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };
    

      const handleEditUser = async (e) => { 
        e.preventDefault();
        try {
          const formElement = document.getElementById("editUserForm");
          const formData = new FormData(formElement);
          formData.set("status", formData.get("status") === "on" ? 1 : 0);
    
          const response = await axios.post(
            `${config.api_url}/ws-update-user`,
            formData,
            {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }
          );

          if (response.data.success) {
            Swal.fire({
              title: "Success!",
              text: "User updated successfully.",
              icon: "success",
              timer: 1500, 
              showConfirmButton: false,
            })
              .then(() => {
                if (editModalRef.current) {
                  const bsModal = window.bootstrap.Modal.getInstance(editModalRef.current);
                  if (bsModal) bsModal.hide();
                }
                initializeDataTable();
                document.getElementById("editUserForm").reset();
              })
              .catch((error) => {
                console.error("Error refreshing user:", error);
              });
          } else {
            Swal.fire({
              title: "Error!",
              text: "An error occurred while updating a user.",
              icon: "error",
              timer: 1500, // Automatically close after 1500ms
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error("Request Error:", error);
          Swal.fire({
            title: "Error!",
            text: "An error occurred while updating a user.",
            icon: "error",
            timer: 1500, // Automatically close after 1500ms
            showConfirmButton: false,
          });
        }
      };

      const handleEditClick = async (id) => {
            document.getElementById("editUserForm").reset();
            const  formData = new FormData();
            formData.set('user_id', id);
              const response = await axios.post(`${config.api_url}/ws-get-user-by-id`, formData, {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              });            

              if (response.data.user) 
                {
                  $('#user_id').val(id);
                  $('#name').val(response.data.user.name);
                  $('#email').val(response.data.user.email);
                  $('#mobile').val(response.data.user.mobile);
                  $('#designation').val(response.data.user.designation);
                  $('#department').val(response.data.user.department);
                  $('#role').val(response.data.user.role_id).change();
                  (response.data.user.status == "1") ? $('#status').prop('checked', true) : $('#status').prop('checked', false);
                  // alert(response.data.user.image);
                  if(response.data.user.image)
                  {
                    $('#prev_image').attr('src', response.data.user.image);
                  }
                  else{
                    $('#prev_image').attr('src', "https://payroll.peoplehrm.in/uploads/user_image/No_image_available.svg.jpg");
                  }
                }
      };

      const AddUser = () =>{
        document.getElementById('addUserForm').reset();
      }

      const EditUser = () =>{
        document.getElementById('editUserForm').reset();
      }

      function handleChangePassword(e) {
        setShowPasswordInput(e.target.checked);
      }
      

    return (
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12">
                        <a href="#"><i className="fa fa-home"></i></a> &gt; 
                        <Link to="/Dashboard">Dashboard</Link> &gt; 
                        <span className="last-crumb">User</span>
                    </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                              <div class="topsearch-area">
                                <div class="row">
                                <div class="col-12 col-lg-4 col-sm-4">
                                  <div class="row">
                                    <label for="inputText" class="col-sm-2 col-form-label">User</label>
                                    <div class="col-sm-6">
                                    <input type="text" className="search-area form-control" name="searchValue" id="searchValue" onChange={initializeDataTable} placeholder="Search User" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-lg-8 col-sm-8">
                                  <div style={{ float: "right" }}>
                                  <a href="#" className="create-catalogue-btn" onClick={AddUser} data-bs-toggle="modal" data-bs-target="#add_user">Add New User</a>
                                  </div>
                                </div>
                                </div>
                              </div>
                                <div style={{ width: '100%', float: 'left' }}>
                                    <div className="verical-scrl-wrapper userlst-tbl" id="style-3">
                                        <table className="table table-bordered dataTable" ref={tableRef}>
                                            <thead>
                                            <tr>
                                              <th>Emp ID</th>
                                              <th>User ID</th>
                                              <th>Name</th>
                                              <th>Mobile</th>
                                              <th>Designation</th>
                                              <th>Department</th>
                                              <th>Role</th>
                                              <th>Status</th>
                                              <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
      {/* Add New User Modal */}
      <div className="modal fade" id="add_user" tabIndex="-1" ref={modalRef} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New User</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body add-product-popup">

                  <Formik
              initialValues={{
                name: '',
                email: '',
                mobile: '',
                password: '',
                designation: '',
                department: '',
                role: '',
                status: true,
                user_image: null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleAddUser}
            >
              {({ setFieldValue }) => (
                <Form id="addUserForm">
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name *</label>
                    <div className="col-sm-4">
                      <Field type="text" className="form-control" name="name" />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </div>
                    <label htmlFor="email" className="col-sm-2 col-form-label">Email (User ID) *</label>
                    <div className="col-sm-4">
                      <Field type="text" className="form-control" name="email" />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="mobile" className="col-sm-2 col-form-label">Mobile</label>
                    <div className="col-sm-4">
                      <Field type="text" className="form-control" name="mobile" />
                      <ErrorMessage name="mobile" component="div" className="text-danger" />
                    </div>
                    <label htmlFor="password" className="col-sm-2 col-form-label">Password *</label>
                    <div className="col-sm-4">
                      <Field type="password" className="form-control" name="password" />
                      <ErrorMessage name="password" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="designation" className="col-sm-2 col-form-label">Designation</label>
                    <div className="col-sm-4">
                      <Field type="text" className="form-control" name="designation" />
                    </div>
                    <label htmlFor="department" className="col-sm-2 col-form-label">Department</label>
                    <div className="col-sm-4">
                      <Field type="text" className="form-control" name="department" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="role" className="col-sm-2 col-form-label">Role *</label>
                    <div className="col-sm-4">
                      <Field as="select" name="role" className="form-select orgselect">
                        <option value="" disabled>Select Role</option>
                        {roleData.map((item, index) => (
                          <option key={index} value={item.id}>{item.role}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="role" component="div" className="text-danger" />
                    </div>
                    <label htmlFor="status" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-4">
                      <label className="switch">
                        <Field type="checkbox" name="status" className="switch-input" />
                        <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="user_image" className="col-sm-2 col-form-label">User Image</label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="file"
                        name="user_image"
                        onChange={(event) => {
                          setFieldValue("user_image", event.currentTarget.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row padd-l-13px">
                    <div className="col-sm-12">
                      <button type="submit" className="popup-innerbtn-common right">Save</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
          </div>
        </div>
      </div>
     
        {/* Edit User Modal */}
        <div className="modal fade" id="edit_user" tabIndex="-1" ref={editModalRef} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit User</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body add-product-popup">
            <Formik
              initialValues={{
                name: '',
                email: '',
                mobile: '',
                password: '',
                designation: '',
                department: '',
                role: '',
                status: true,
                user_image: null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleAddUser}
            >
            {({ setFieldValue }) => (
              <form id='editUserForm' >
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="inputText" className="col-sm-2 col-form-label">Name *</label>
                  <div className="col-sm-4">
                    <input  type="hidden" name="user_id" id='user_id' />
                    <input  type="text"  className="form-control"  name="name" id='name' />
                    <ErrorMessage name="Name" component="div" className="text-danger" />
                  </div>
                  <label htmlFor="email" className="col-sm-2 col-form-label">Email (User ID) *</label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control"  name="email" id='email' />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="mobile" className="col-sm-2 col-form-label">Mobile </label>
                  <div className="col-sm-4">
                    <input  type="text" className="form-control" name="mobile" id='mobile' minLength={10} maxLength={10}  />
                  </div>
                   <label htmlFor="department" className="col-sm-2 col-form-label">Department </label>
                  <div className="col-sm-4">
                    <input    type="text"   className="form-control"   name="department"  id='department' />
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="designation" className="col-sm-2 col-form-label">Designation </label>
                  <div className="col-sm-4">
                    <input  type="text" className="form-control" name="designation"  id='designation'   />
                  </div>
                  <label htmlFor="role" className="col-sm-2 col-form-label">Role *</label>
                  <div className="col-sm-4">
                  <select name="role" className="form-select orgselect" defaultValue=''  id='role'>
                        <option value="" disabled>Select Role</option>
                        {roleData.map((item, index) => (
                          <option key={index} value={item.id}>{item.role}</option>
                        ))}
                      </select>

                       
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="status" className="col-sm-2 col-form-label">Status </label>
                  <div className="col-sm-4">
                    <label className="switch">
                      <input className="switch-input" type="checkbox" name="status" id='status' />
                      <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                  
                  <label htmlFor="status" className="col-sm-2 col-form-label">Change Password </label>
                  <div className="col-sm-4">
                    <label className="switch">
                      <input
                        className="switch-input"
                        onChange={handleChangePassword}
                        type="checkbox"
                        name="changePassword"
                        id='changePassword'
                      />
                      <span className="switch-label" data-on="Yes" data-off="No"></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="compType" className="col-sm-2 col-form-label" >User Image</label>
                  <div className="col-sm-4"><input className="form-control" type="file" name="user_image" id='user_image' /></div>
                 { showPasswordInput && ( 
                  <>
                  <label htmlFor="compType" className="col-sm-2 col-form-label" style={{display:'block'}}  >Password</label>
                  <div className="col-sm-4" style={{display:'block'}} ><input className="form-control" type="text" placeholder='Enter Password' name="password" id='newPassword' /></div>
                  </>  )} 
                </div>
                <div className="row mb-3 padd-l-13px">
                  
                  <img  name="prev_image" id="prev_image" alt="User Image" style={{ marginLeft: '284px', width: '100px', height: '70px' }}  />    
                </div>

                <hr />
                <div className="row padd-l-13px">
                  <div className="col-sm-12">
                    <button type="submit" onClick={handleEditUser} className="popup-innerbtn-common right">Update</button>
                  </div>
                </div>
              </form>
              )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
            <Footer />
        </div>
    );
};

export default UserList;
