// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Common_Files/Login";
import Dashboard from "./components/Common_Files/Dashboard";
//Master Routes
import Products from './components/Master/Products';
import ViewProduct from './components/Master/ViewProduct';
import Supplier from './components/Master/Supplier';
import Customer from './components/Master/Customer';
import WarehouseMaster from './components/Master/WarehouseMaster';
import ProductCategoryMaster from './components/Master/ProductCategoryMaster';
import Brand from './components/Master/Brand';
import UserList from './components/user/UserList';
import ProductBatch from './components/Purchase/ProductBatch';

//Purchase Routes
import NewPurchaseOrder from './components/Purchase/NewPurchaseOrder';
import EditNewPurchaseOrder from './components/Purchase/EditNewPurchaseOrder';
import GRN from './components/Purchase/GRN';
import ConfirmGRN from './components/Purchase/ConfirmGRN';
import PurchaseHistory from './components/Purchase/PurchaseHistory';
import PurchaseProforma from './components/Purchase/PurchaseProforma';
import EditPO from './components/Purchase/EditPO';
import PurchaseHistoryView from './components/Purchase/PurchaseHistoryView';

import PartialGRN from './components/Purchase/PartialGRN';
import CurrentSalesOrder from './components/Sales/CurrentSalesOrder';
import NewSalesOrder from './components/Sales/NewSalesOrder';
import NewSalesOrderEdit from './components/Sales/NewSalesOrderEdit';
import Proforma from './components/Sales/Proforma';
import DeliveryNote from './components/Sales/DeliveryNote';
import CreditNote from './components/Sales/CreditNote';
import ResizableTable from './components/Sales/ResizableTable';
import CreatePO from './components/Sales/CreatePO';
import SalesCollectionNote from './components/Sales/SalesCollectionNote';

import OrderHistory from './components/Sales/OrderHistory';
import DeleteOrder from './components/Sales/DeleteOrder';
import Inventory from "./components/Purchase/Inventory";
import Bank from "./components/Master/Bank";
import NestedPopup from "./components/Master/NestedPopup";

import SupplierBill from "./components/Accounts/SupplierBill";
import PayBills from "./components/Accounts/PayBills";
import SupplierCredit from "./components/Accounts/SupplierCredit";
import PurchaseLedger from "./components/Accounts/PurchaseLedger";


function App() {
  return (
    <Router>
      <Routes>     
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/ProductBatch" element={<ProductBatch />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/ViewProduct" element={<ViewProduct />} />
        <Route path="/Supplier" element={<Supplier />} />
        <Route path="/Customer" element={<Customer />} />
        <Route path="/UserList" element={<UserList />} />
        <Route path="/NewPurchaseOrder" element={<NewPurchaseOrder />} />
        <Route path="/EditNewPurchaseOrder/:id" element={<EditNewPurchaseOrder />} />
        <Route path="/GRN" element={<GRN />} />
        <Route path="/ConfirmGRN/:id" element={<ConfirmGRN />} />
        <Route path="/PartialGRN" element={<PartialGRN />} />
        <Route path="/PurchaseHistory" element={<PurchaseHistory />} />   
        <Route path="/PurchaseProforma/:id" element={<PurchaseProforma />} />
        <Route path="/EditPO/:id" element={<EditPO />} />
        <Route path="/PurchaseHistoryView/:id" element={<PurchaseHistoryView />} />        

        <Route path="/WarehouseMaster" element={<WarehouseMaster />} />
        <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
        <Route path="/Brand" element={<Brand />} />
        <Route path="/CurrentSalesOrder" element={<CurrentSalesOrder />} />
        <Route path="/NewSalesOrderEdit/:id" element={<NewSalesOrderEdit />} />
        <Route path="/NewSalesOrder" element={<NewSalesOrder />} />
        <Route path="/ResizableTable" element={<ResizableTable />} />
        <Route path="/CreatePO/:id" element={<CreatePO />} />
        <Route path="/salesCollectionNote/:id" element={<SalesCollectionNote />} />
                
        <Route path="/Proforma" element={<Proforma />} />
        <Route path="/DeliveryNote" element={<DeliveryNote />} />
        <Route path="/CreditNote" element={<CreditNote />} />
        <Route path="/Inventory" element={<Inventory />} />
        <Route path="/WarehouseMaster" element={<WarehouseMaster />} />
        <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
        <Route path="/OrderHistory" element={<OrderHistory />} />
        <Route path="/DeleteOrder" element={<DeleteOrder />} />
        <Route path="/Bank" element={<Bank />} />
        {/* <Route path="/NestedPopup" element={<NestedPopup />} /> */}

        <Route path="/SupplierBill" element={<SupplierBill />} />
        <Route path="/PayBills" element={<PayBills />} />
        <Route path="/SupplierCredit" element={<SupplierCredit />} />
        <Route path="/PurchaseLedger" element={<PurchaseLedger />} />
        
      </Routes>
    </Router>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
