import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Accounts/PurchaseLedger';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { DateRangePicker } from 'rsuite';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

const PurchaseLedger = () => {
    const [reportrange, setReportrange] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [completeTrips, setCompleteTrips] = useState([]);
    const [draw, setDraw] = useState(1);
    const [start, setStart] = useState(0);
    const [length, setLength] = useState(50);

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
          const [start, end] = dates;
          setFromDate(format(start, 'yyyy-MM-dd'));
          setToDate(format(end, 'yyyy-MM-dd'));
          setReportrange(`${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`);
        }
      };

      const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
    
        if (!accessToken) {
          window.location.href = '/login';
          return;
        }
    
        const formData = new FormData();
        formData.append('searchByFromdate', fromDate);
        formData.append('searchByTodate', toDate);
        formData.append('draw', draw);
        formData.append('start', start);
        formData.append('length', length);
        formData.append('searchValue', selectedRole);
    
        const apiUrl = `${config.api_url}/ws-purchase-history-list`;
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });
    
          if (!response.ok) {
            throw new Error('Failed to fetch purchase data');
          }
    
          const data = await response.json();
          setCompleteTrips(data.data);
          console.log(data.data);
        } catch (error) {
          console.error('Error fetching purchase data:', error);
        }
      }; 


      useEffect(() => {
        fetchData();
      }, [fromDate, toDate, selectedRole]);
    
    



    return (
        <div>

            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Purchase Ledger</span> </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">

                                    <div className="dashform">
                                        <div class="topsearch-area">
                                            <div class="row">
                                                <div class="col-12 col-lg-3 col-sm-3">
                                                    <div class="row">
                                                        <label for="inputText" class="col-sm-6 col-form-label">P.O ID/Supplier/Bill No., Job Ref</label>
                                                        <div class="col-sm-6">
                                                            <input type="text" className="search-area form-control" placeholder="Search" name="product_name" id="product_name" />
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-1 col-sm-1">
                                                    <select name="warehouse_id" id="warehouse_id" className="form-select" defaultValue=''>
                                                    <option value="" disabled>Type</option>
                                                    <option value="all">All</option>

                                                </select>
                                                </div>

                                                <div class="col-12 col-lg-2 col-sm-2">
                                                    <select name="warehouse_id" id="warehouse_id" className="form-select" defaultValue=''>
                                                    <option value="" disabled>Select Supplier</option>
                                                    <option value="all">Supplier</option>

                                                </select>
                                                </div>
                                                <div class="col-12 col-lg-3 col-sm-3">
                                                    <div class="row">
                                                        <label for="inputText" class="col-sm-3 col-form-label">Date Range</label>
                                                        <div className="col-sm-7">
                            <DateRangePicker
                              value={[fromDate ? new Date(fromDate) : undefined, toDate ? new Date(toDate) : undefined]}
                              onChange={handleDateChange}
                              format="MM/dd/yyyy"
                            />
                          </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div>



                                        <div className="verical-scrl-wrapper common-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Raised On</th>
                                                        <th>Type</th>
                                                        <th>Bill No</th>
                                                        <th>Job Ref</th>
                                                        <th>P.O ID</th>
                                                        <th>Supplier (ID)</th>
                                                        <th>Value</th>
                                                        <th>Balance</th>
                                                        <th>Due Date</th>
                                                        <th>Created By</th>
                                                        <th>Status</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>


                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>

                                                    <tr>
                                                        <td><a href='/SupplierBill'>1003</a></td>
                                                        <td>15/07/2024</td>
                                                        <td>INV</td>

                                                        <td>56342</td>
                                                        <td>n5080/5647</td>
                                                        <td><a href='#'>12714</a></td>
                                                        <td><a href='#'>Pepsi & Co(001)</a></td>
                                                        <td>-15000.00</td>
                                                        <td>0</td>
                                                        <td>10/08/2024</td>
                                                        <td>Ganesh</td>
                                                        <td>Paid</td>
                                                        <td><a href='/PayBills'>Pay</a></td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                    </div>

                                    <hr/>

                                <h5>Aging (i) Requires supplier to be selected from the filter</h5>
                                <div className="verical-scrl-wrapper common-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Balance</th>
                                                        <th>UnAlloc</th>
                                                        <th>0 to 1</th>
                                                        <th>1 to 2</th>
                                                        <th>2 to 3</th>
                                                        <th>3 to 4</th>
                                                        <th>4 plus</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>500</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        
                                                    </tr>

                                                   
                                                </tbody>
                                            </table>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>



            <Footer />
        </div>
    );
};

export default PurchaseLedger; 
