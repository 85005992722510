import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from '../Common_Files/Config';
import axios from 'axios';


const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateDeliveryNotePDF = async (id) => {
  const accessToken = localStorage.getItem('accessToken');

  const formData = new FormData();
  formData.set('sale_id', id);
  const apiUrl = `${config.api_url}/ws-get-sales-by-id`;

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const data = await response.data;
    console.log(data);

    const totalSalesQty = data.product.reduce((acc, item) => acc + parseInt(item.salesQty, 10), 0);
    const totalPalletQty = data.product.reduce((acc, item) => acc + parseInt(item.pallet_qty, 10), 0);

    const doc = new jsPDF('landscape'); // Set to landscape orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Margins
    const margin = 10;
    let y = margin;

    // Header sections
    const headerSections1 = [
      [
        { text: 'Star Pacific UK LTD', size: 13, bold: true },
        { text: 'Unit 6 Abenglen Industrial Estate,', size: 8, bold: false },
        { text: 'Betam Road, Hayes', size: 8, bold: false },
        { text: 'Middlesex, London UB3 1AT', size: 8, bold: false },
        { text: 'United Kingdom', size: 8, bold: false },
      ],
      [
        { text: 'Co. Reg. No.: 7298396', size: 8, bold: true },
        { text: 'VAT Reg. No.: GB994533184', size: 8, bold: false },
        { text: 'Tel: 44-208 329 3800', size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: false }],
      [{ text: '', size: 8, bold: false }],
    ];

    const headerSections3 = [
      [
        { text: `Delivery Address: ${data.sale.address_line1}`, size: 8, bold: false },
        { text: `${data.sale.address_line2}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `${data.sale.city}`, size: 8, bold: false },
        { text: `${data.sale.post_code}`, size: 8, bold: false },
        { text: `${data.sale.country}`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: true }],
      [
        { text: `Customer Ref: ${data.sale.refno}`, size: 8, bold: false },
        { text: `Booking Ref: ${data.sale.booking_ref}`, size: 8, bold: false },
        { text: `Booking Time: ${data.sale.order_date}`, size: 8, bold: false },
        { text: `Delivery Date: ${data.sale.delivery_date}`, size: 8, bold: false },
        { text: `Delivery Note: ${data.sale.notes}`, size: 8, bold: false },
        { text: `SO#: ${data.sale.reference_no}`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: false }],
    ];

    const sectionWidth = (pageWidth - margin * 2) / 4; // Adjust for margins

    // Render header sections
    const renderSections = (sections) => {
      sections.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 15; // Add a little space after the last header section
    };

    renderSections(headerSections1);
    renderSections(headerSections3);

    // Render table
    const columns = [
      { title: 'Line No', dataKey: 'line_no', size: 8, bold: false },
      { title: 'SKU Code', dataKey: 'sku_code', size: 8, bold: false },
      { title: 'Product Description', dataKey: 'pdt_desc', size: 8, bold: false },
      { title: 'Pack', dataKey: 'pack', size: 8, bold: false },
      { title: 'Size', dataKey: 'size', size: 8, bold: false },
      { title: 'Unit Barcode', dataKey: 'unit_brcode', size: 8, bold: false },
      { title: 'Case Qty', dataKey: 'case_qty', size: 8, bold: false },
      { title: 'Pallet Qty', dataKey: 'pallete_qty', size: 8, bold: false },
    ];


    const tableBody = data.product.map((item, index) => [
      index + 1,
      item.code,
      item.product_desc,
      item.pack,
      item.size,
      item.unit_barcode,
      item.salesQty,
      item.pallet_qty,
    ]);

    // Add an empty row with totals
    tableBody.push([
      '', '', '', '','', 'Total : ', totalSalesQty.toString(), totalPalletQty.toString(),
    ]);

    // Render the table
    doc.autoTable({
      startY: y,
      head: [columns.map(col => col.title)],
      body: tableBody,
      theme: 'grid',
      headStyles: {
        fillColor: '#f8f9fa',
        textColor: '#404040',
        size: '13px',
      },
      margin: { top: 10 },
    });


    y = doc.autoTable.previous.finalY + 12; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 20; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    // Define your footer sections here
    const footerSections1 = [
      [
        { text: `Notes: Delivered on ${data.sale.delivery_date}`, size: 8, bold: false },
        { text: `Ref: Delivered on ${data.sale.refno}`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: false }],
      [
        { text: `Gross Weight: ${data.sale.gross_weight} KGS`, size: 8, bold: false },
        { text: `CBM: ${data.sale.cube} M3`, size: 8, bold: false },
        { text: `Net Weight: ${data.sale.net_weight} KGS`, size: 8, bold: false },
      ]
    ];

    const footerSections2 = [
      [
        { text: 'CHEP Standard', size: 8, bold: false },
        { text: 'LPR Standard', size: 8, bold: false },
        { text: 'IPP Standard', size: 8, bold: false },
        { text: 'CHEP Euro', size: 8, bold: false },
        { text: 'Others', size: 8, bold: false },
      ],
      [
        { text: 'Driver', size: 8, bold: false },
        { text: 'Loaded in good condition and correct pallet count', size: 8, bold: false },
        { text: 'Name -------------------------------', size: 8, bold: false },
        { text: 'Sign -------------------------------', size: 8, bold: false },
      ],
      [
        { text: 'Warehouse', size: 8, bold: false },
        { text: 'Visual inspection of pallets and product carried out', size: 8, bold: false },
        { text: 'Name -------------------------------', size: 8, bold: false },
        { text: 'Sign -------------------------------', size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: false }],
    ];

    const footerSections3 = [
      [
        { text: 'Subject to Star Pacific Limited terms and conditions of sale, a copy of which is available on request', size: 8, bold: false },
        { text: 'Star Pacific UK LTD (No. 7298396)', size: 8, bold: false },
        { text: 'Reservation of Title. The property in and the title to all goods sold by Star Pacific UK LTD shall not pass to the buyer until all monies due and owing have been paid in full', size: 8, bold: false },
      ]
    ];

    // Center footerSections3
    const centerFooter = (footerSection) => {
      footerSection.forEach((section, index) => {
        const centerX = (pageWidth - margin * 2) / 2; // Center position

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, centerX, y + i * 5, { align: 'center' }); // Center align text
        });

        y += 15; // Add space after each footer section
      });
    };

    // Add footer sections with space checks
    addFooterWithSpaceCheck(footerSections1);
    addFooterWithSpaceCheck(footerSections2);
    centerFooter(footerSections3); // Center the third footer

    const uniqueFilename = generateUniqueFilename('DeliveryNote', 'pdf');

    // Save the document with the unique filename
    doc.save(uniqueFilename);

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generateDeliveryNotePDF;
