import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewSupplier from "../Master/ViewSupplier";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const GRN = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [searchValue, setSearchValue] = useState('');
  const modalRef = useRef(null);
  const editModalRef = useRef(null);
  const tableRef = useRef(null);
  const accessToken = localStorage.getItem('accessToken');

  const initializeDataTable = () => {
    const $table = $(tableRef.current);

    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    $table.DataTable({
      processing: true,
      serverSide: true,
      ordering: false,
      ajax: {
        url: `${config.api_url}/ws-grn-list`,
        type: 'POST',
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue
          });
        },
        dataSrc: function (json) {
          return json.data;
        }
      },
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      columns: [
        { data: 'created_date', width: "6%"},     
        { data: null, width: '10%',
          render : function(data, type, row){
            return `<a href="./editNewPurchaseOrder/${row.id}">${row.reference_no}</a>`;
          }
        },
        
        { data: 'name', 
          render: function(data, type, row) {
              return `<a href="#"   class="view-supplier" data-bs-toggle="modal" data-bs-target="#viewsupplier" data-id="${row.supplier_id}">${data}</a>`;
              
          } 
        },
        // { data: 'grand_total-vat_amount', width: "7%",  className: 'dt-type-numeric' },
         { 
        data: null, // Use null since we are calculating this value
        width: "7%", 
        className: 'dt-type-numeric',
        render: function(data, type, row) 
        {
            const grandTotal = parseFloat(row.grand_total) || 0;
            const vatAmount = parseFloat(row.vat_amount) || 0;
            const difference = grandTotal - vatAmount;
            return difference.toFixed(2)
        }
    },
        { data: 'vat_amount', width: "7%" ,className: 'dt-type-numeric'},
        { data: 'grand_total', width: "7%" ,className: 'dt-type-numeric' },
        { data: 'status', width: "7%" },
        { data: null, width: "7%", render: (data, type, row) => `
            <a href="${`/ConfirmGRN/${row.id}`}" title="Edit"><i class="fa fa-pencil"></i></a>
            <a href="#" title="P.O. Collection Note"><i class="fa fa-download"></i></a>`
        }
      ],
      columnDefs: [{ orderable: false, targets: '_all' }],
      pageLength: 10,
    });
  };

  useEffect(() => {
    initializeDataTable();

    return () => {
      if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [searchValue]); 

  const handleSearchChange = (event) => {   
    setSearchValue(event.target.value);
  };

  return (
    <div>    
      <Header />
      <ViewSupplier/>
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt;
              <a href="dashboard">Dashboard</a> &gt;
              <span className="last-crumb">Goods Receive Notes</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="dashboard">
                <div className='topsearch-area'>
                  <div className="row">
                    <div className="col-12 col-lg-4 col-sm-4">
                      <div className="row">
                        <label htmlFor="inputText" className="col-sm-5 col-form-label">P.O ID/Supplier</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="search-area"
                            placeholder="Search"
                            autoComplete="off"
                            value={searchValue}
                            onChange={handleSearchChange}
                         
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="verical-scrl-wrapper common-table grn-tbl" id="style-3">
                    <table className="table table-bordered dataTable" id="dsTable" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>Raised On</th>
                          <th>P.O ID</th>
                          <th>Supplier(ID)</th>
                          <th>Order Amount</th>
                          <th>Tax</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>   
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GRN;
