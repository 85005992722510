import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const Header = () => {
    const [activeLink, setActiveLink] = useState('Dashboard');
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [name, setName] = useState('');
    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };

    const handleMobileNavToggle = () => {
        setMobileNavOpen(!mobileNavOpen);
    };
    useEffect(() => {
        const storedName = localStorage.getItem('name');
        setName(storedName || 'No name found');
    }, []);

    // Optional: Function to update localStorage and state
    const updateName = (newName) => {
        localStorage.setItem('name', newName);
        setName(newName);
    };
    return (
        <header id="header" className="header fixed-top" data-scrollto-offset="0">
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <a href="index.html" className="logo-area align-items-center scrollto me-auto me-lg-0">
                    <img src="/assets/img/logo.jpg" alt="Logo" />
                </a>

                <nav id="navbar" className={`navbar ${mobileNavOpen ? 'mobile-nav-active' : ''}`}>
                    <ul className={`nav-menu ${mobileNavOpen ? 'mobile-nav-show' : ''}`}>
                        <li>
                            <Link
                                to="/Dashboard"
                                className={activeLink === 'Dashboard' ? 'active' : ''}
                                onClick={() => handleSetActiveLink('Dashboard')}
                            >
                                <span> Dashboard</span>
                            </Link>
                        </li>
                        <li className="dropdown">
                            <Link to="#">
                                <span>Purchases</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><a href="/NewPurchaseOrder"><span>New Purchase Order</span></a></li>
                                <li><a href="/GRN"><span>GRN</span></a></li>
                                <li><a href="/PurchaseHistory"><span>Purchases History</span></a></li>
                               
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#">
                                <span>Sales</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="/NewSalesOrder"><span>New Sales Order</span></Link></li>
                                <li><Link to="/CurrentSalesOrder">Current Sales Order</Link></li>
                                <li><Link to="/OrderHistory">Order History</Link></li>
                                <li><Link to="/DeleteOrder">Deleted Orders</Link></li>
                                <li><Link to="/CreditNote">Credit Notes</Link></li>
                                <li><Link to="#">Delivery</Link></li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#">
                                <span>Masters</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="/UserList"><span>User Master</span></Link></li>
                                <li><Link to="/Bank"><span>Bank</span></Link></li>
                                <li><Link to="/Supplier"><span>Supplier Master</span></Link></li>
                                <li><Link to="/Customer"><span>Customer Master</span></Link></li>
                                <li><Link to="/WarehouseMaster"><span>Warehouse Master</span></Link></li>
                                <li className="dropdown"><a href="#"><span>Product Master</span> <i className="bi bi-chevron-right dropdown-indicator"></i></a>
                                    <ul>
                                        <li><Link to="/Products"><span>Product List</span></Link></li>
                                        <li><Link to="/ProductCategoryMaster"><span>Product Category</span></Link></li>
                                        <li><Link to="/Brand"><span>Product Brand</span></Link></li>

                                    </ul>
                                </li>
                                {/* <li><Link to="/Products"><span>Products</span></Link></li>
                                <li><Link to="/ProductCategoryMaster"><span>Product Category</span></Link></li>
                                <li><Link to="/Brand"><span>Product Brand</span></Link></li> */}
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#">
                                <span>Accounts</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>

                                {/* <li><Link to="/SupplierBill"><span>Supplier</span></Link></li> */}
                                <li className="dropdown"><a href="#"><span>Supplier</span> <i className="bi bi-chevron-right dropdown-indicator"></i></a>
                                    <ul>
                                        <li><Link to="/SupplierBill"><span>Supplier Bill</span></Link></li>
                                        <li><Link to="/SupplierCredit"><span>Supplier Credit</span></Link></li>
                                        <li><Link to="/PayBills"><span>Pay Bills</span></Link></li>

                                    </ul>
                                </li>
                                
                                <li><Link to="/PurchaseLedger"><span>Purchase Ledger</span></Link></li>
                                
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#">
                                <span>Reports</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="#"><span>Purchase Report</span></Link></li>
                                <li><Link to="#"><span>Sales Report</span></Link></li>
                                <li><Link to="#"><span>Stock Report</span></Link></li>
                                <li><Link to="#"><span>Profit And Loss Report</span></Link></li>
                                <li><Link to="#"><span>Lead Time Report</span></Link></li>
                                <li><Link to="#"><span>Retro Report</span></Link></li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <Link to="#">
                                <span>Settings</span> <i className="bi bi-chevron-down dropdown-indicator"></i>
                            </Link>
                            <ul>
                                <li><Link to="#"><span>Currency Conversion</span></Link></li>
                                <li><Link to="#"><span>Change Password</span></Link></li>
                            </ul>
                        </li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle d-none" onClick={handleMobileNavToggle}></i>
                </nav>

                <div className="header-nav ms-auto userprofile-area">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src="../assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block dropdown-toggle ps-2"> {name}</span>
                            </a>
                            <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${mobileNavOpen ? 'mobile-nav-show' : ''}`}>
                               
                                {/* <li><hr className="dropdown-divider" /></li> */}
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-person"></i> <span>My Profile</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-gear"></i> <span>Account Settings</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center" to="#"><i className="bi bi-question-circle"></i> <span>Need Help?</span></Link></li>
                                <li><Link className="dropdown-item d-flex align-items-center last-li" to="/"><i className="bi bi-box-arrow-right"></i> <span>Sign Out</span></Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
