import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';

const PayBills = () => {
    const [suppliers, setSuppliers] = useState([]); // Initialize as an array
    const [bills, setBills] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [payingAmount, setPayingAmount] = useState('');

    useEffect(() => {
        axios.get(`${config.api_url}/ws-get-suppliers`)
            .then(response => {
                console.log('Suppliers Response:', response.data); // Debugging line
                // Access the supplier array from the response
                if (response.data.supplier && Array.isArray(response.data.supplier)) {
                    setSuppliers(response.data.supplier);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setSuppliers([]);
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });
    }, []);
    

    const handleSupplierChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);
    
        // Fetch bills for the selected supplier
        if (supplierId) {
            axios.get(`${config.api_url}/ws-supplier-bills/${supplierId}`) // Adjust the endpoint as necessary
                .then(response => {
                    // Assuming the response structure contains bills directly in an array
                    if (Array.isArray(response.data)) {
                        setBills(response.data);
                    } else {
                        console.error('Unexpected data format for bills:', response.data);
                        setBills([]); // Reset if not an array
                    }
                })
                .catch(error => {
                    console.error('Error fetching bills:', error);
                    setBills([]); // Reset on error
                });
        } else {
            setBills([]); // Clear bills if no supplier is selected
        }
    };
    
    const handlePayingAmountChange = (index, value) => {
        const updatedBills = [...bills];
        updatedBills[index].payingAmount = value;
        setBills(updatedBills);
    };

    return (
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="/Dashboard">Dashboard</a> &gt;
                            <span className="last-crumb">Pay Bills (Supplier Payment)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left' }}>
                                        <div className="newsalesorderform">
                                            <div style={{ float: "right", width: "100%" }}>
                                                <a href="#" className="create-catalogue-btn"><i className='fa fa-plus'></i>&nbsp;New Supplier</a>
                                            </div>
                                            <hr />
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="supplier" className="col-form-label">Select Supplier * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                       <select className="form-control" id="supplier" onChange={handleSupplierChange}>
                                                        <option value="">Select Supplier</option>
                                                        {suppliers.map(supplier => (
                                                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                                        ))}
                                                    </select>


                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="delivery_date" className="col-form-label">Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" />
                                                        </div>
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bank" className="col-form-label">Bank </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <select name="delivery_id" className="form-select orgselect">
                                                                <option>Select Bank</option>
                                                                <option>ICICI</option>
                                                                <option>HDFC</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="details" className="col-form-label">Details </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="transaction_ref" className="col-form-label">Transaction Ref </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="paying_amount" className="col-form-label">Paying Amount</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" disabled placeholder='15400' />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="balance" className="col-form-label">Balance</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" disabled placeholder='15500' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="verical-scrl-wrapper common-table paybill-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>ID</th>
                                                        <th>Raised On</th>
                                                        <th>Type</th>
                                                        <th>Bill No</th>
                                                        <th>Job Ref</th>
                                                        <th>P.O ID</th>
                                                        <th>Supplier (ID)</th>
                                                        <th>Value</th>
                                                        <th>Balance</th>
                                                        <th>Pay</th>
                                                        <th>Due Date</th>
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bills.map((bill, index) => (
                                                        <tr key={bill.id}>
                                                            <td>
                                                                <label className="checkbox">
                                                                    <input type="checkbox" name='save_as_proforma' />
                                                                    <span className="default"></span>
                                                                </label>
                                                            </td>
                                                            <td><a href='#'>{bill.id}</a></td>
                                                            <td>{bill.created_date}</td>
                                                            <td>{bill.type}</td>
                                                            <td>{bill.billNo}</td>
                                                            <td>{bill.job_ref}</td>
                                                            <td><a href='#'>{bill.reference_no}</a></td>
                                                            <td><a href='#'>{bill.supplierId}</a></td>
                                                            <td>{bill.value}</td>
                                                            <td>{bill.balance}</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Amount"
                                                                    value={bill.payingAmount || ''}
                                                                    onChange={(e) => handlePayingAmountChange(index, e.target.value)}
                                                                />
                                                            </td>
                                                            <td>{bill.dueDate}</td>
                                                            <td>{bill.createdBy}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                        <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                            <button type="button" className="saveclose">Save & Close</button>
                                            <button type="button" className="savenew">Save & New</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default PayBills;
