import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import axios from 'axios';

const SupplierCredit = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [purchaseOrders, setPurchaseOrders] = useState([]);

    useEffect(() => {
        // Fetch suppliers when the component mounts
        axios.get(`${config.api_url}/ws-get-suppliers`)
            .then(response => {
                setSuppliers(response.data.supplier); // Adjust according to your API response structure
            })
            .catch(error => console.error(error));
    }, []);

    const handleSupplierChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);

        // Fetch Purchase Orders for the selected supplier
        if (supplierId) {
            axios.get(`${config.api_url}/ws-supplier-po/${supplierId}`) // Adjust this endpoint as needed
                .then(response => {
                    setPurchaseOrders(response.data); // Assume the response is an array of POs
                })
                .catch(error => console.error(error));
        } else {
            setPurchaseOrders([]);
        }
    };

    return (
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="/Dashboard">Dashboard</a> &gt;
                            <span className="last-crumb">Supplier Credit</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left', width: '100%' }}>
                                        <div className="newsalesorderform">
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="supplier" className="col-form-label">Select Supplier * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <select className="form-control" id="supplier" onChange={handleSupplierChange}>
                                                                <option value="">Select Supplier</option>
                                                                {suppliers.map(supplier => (
                                                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Other fields like Bill Date, Bill No */}
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bill_date" className="col-form-label">Bill Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="bill_date" id="bill_date" />
                                                        </div>

                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bill_due_date" className="col-form-label">Bill Due Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="bill_due_date" id="bill_due_date" />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bill_no" className="col-form-label">Bill No * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" id="bill_no" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="po" className="col-form-label">Select P.O. * </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <select className="form-control" id="po">
                                                                <option value="">Select Purchase Order</option>
                                                                {purchaseOrders.map(po => (
                                                                    <option key={po.id} value={po.id}>{po.po_number}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="job_ref" className="col-form-label">Job Ref *</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" name="job_ref" className="form-control" id="job_ref" />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="vat" className="col-form-label">VAT %</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <select name="vat" className="form-select orgselect" id="vat">
                                                                <option>Select VAT</option>
                                                                <option>20 %</option>
                                                                <option>0 %</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="purchaseform">
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-sm-12">
                                                        <div className="row mb-3">
                                                            <div className="col-6 col-lg-1 col-sm-1">
                                                                <label htmlFor="net_amount" className="col-form-label">Net Amount </label>
                                                            </div>
                                                            <div className="col-6 col-lg-3 col-sm-3">
                                                                <input type="text" className="form-control" id="net_amount" />
                                                            </div>

                                                            <div className="col-6 col-lg-1 col-sm-1">
                                                                <label htmlFor="vat_amount" className="col-form-label">VAT </label>
                                                            </div>
                                                            <div className="col-6 col-lg-3 col-sm-3">
                                                                <input type="text" className="form-control" id="vat_amount" />
                                                            </div>

                                                            <div className="col-6 col-lg-1 col-sm-1">
                                                                <label htmlFor="total_amount" className="col-form-label">Total </label>
                                                            </div>
                                                            <div className="col-6 col-lg-3 col-sm-3">
                                                                <input type="text" className="form-control" id="total_amount" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                            <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                                <button type="button" className="saveclose">Save & Close</button>
                                                <button type="button" className="savenew">Save & New</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default SupplierCredit;
