import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
// import Datepicker from './DateRangePicker';
// import { DateRangePicker } from 'rsuite';
import { DatePicker } from "rsuite";
// import "rsuite/dist/styles/rsuite-default.css";
// import DateRangePickerComponent from './DateRangePicker';


const Dashboard = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [statisticsData, setStatisticsData] = useState(null); // State to store statistics data

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        if (name === 'startDate') {
            setStartDate(value);
        } else if (name === 'endDate') {
            setEndDate(value);
        }
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const fetchData = async () => {
        // Construct your API endpoint URL
        const apiUrl = `${config.api_url}/get_dashboard_count`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Assuming you store token in localStorage
                },
                body: JSON.stringify({
                    startDate,
                    endDate,
                    role: selectedRole,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch statistics data');
            }

            const data = await response.json();
            console.log(data);
            setStatisticsData(data); // Store fetched data in state
        } catch (error) {
            console.error('Error fetching statistics data:', error);
            // Handle error state if needed
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data when component mounts or dependencies change
    }, [startDate, endDate, selectedRole]); // Add dependencies if needed

    return (
        <div>
            <Header />

            <nav class="breadcrumbs">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i class="fa fa-home"></i> </a>
                            <span class="last-crumb">Dashboard</span> </div>
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <div className="show-order-area dashboard">
                                <div className="dashform">

                                    <div className='row'>
                                        <div className="col-12 col-lg-6 col-sm-6">
                                            <div className='row'>
                                                <div class="col-xxl-3 col-md-6">
                                                    <div class="card info-card sales-card">

                                                        <div class="filter">
                                                            <a class="icon" href="#" ><i class="bi bi-pencil-fill"></i></a>

                                                        </div>

                                                        <div class="card-body">
                                                            <h3 class="card-title">Create Invoice</h3>

                                                            <div class="align-items-center">
                                                                <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                    <i class="bi bi-file-earmark-medical"></i>
                                                                </div>
                                                                <div>
                                                                    {/* <h6>Create Invoice</h6> */}
                                                                    <p>Dicta dolorem harum nulla eius. Ut quidem quidem sit quas.</p>
                                                                    {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-xxl-3 col-md-6">
                                                    <div class="card info-card sales-card">

                                                        <div class="filter">
                                                            <a class="icon" href="#" ><i class="bi bi-pencil-fill"></i></a>

                                                        </div>

                                                        <div class="card-body">
                                                            <h3 class="card-title">Record Expense</h3>

                                                            <div class="align-items-center">
                                                                <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                    <i class="bi bi-record-btn"></i>
                                                                </div>
                                                                <div>
                                                                    {/* <h6>Create Invoice</h6> */}
                                                                    <p>Dicta dolorem harum nulla eius. Ut quidem quidem sit quas.</p>
                                                                    {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-xxl-3 col-md-6">
                                                    <div class="card info-card sales-card">

                                                        <div class="filter">
                                                            <a class="icon" href="#" ><i class="bi bi-pencil-fill"></i></a>

                                                        </div>

                                                        <div class="card-body">
                                                            <h3 class="card-title">Add deposit</h3>

                                                            <div class="align-items-center">
                                                                <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                    <i class="bi bi-credit-card-2-front"></i>
                                                                </div>
                                                                <div>
                                                                    {/* <h6>Create Invoice</h6> */}
                                                                    <p>Dicta dolorem harum nulla eius. Ut quidem quidem sit quas.</p>
                                                                    {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-xxl-3 col-md-6">
                                                    <div class="card info-card sales-card">

                                                        <div class="filter">
                                                            <a class="icon" href="#" ><i class="bi bi-pencil-fill"></i></a>

                                                        </div>

                                                        <div class="card-body">
                                                            <h3 class="card-title">Create cheque</h3>

                                                            <div class="align-items-center">
                                                                <div class="card-icon rounded-circle align-items-center justify-content-center">
                                                                    <i class="bi bi-plus-circle"></i>
                                                                </div>
                                                                <div>
                                                                    {/* <h6>Create Invoice</h6> */}
                                                                    <p>Dicta dolorem harum nulla eius. Ut quidem quidem sit quas.</p>
                                                                    {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-3 col-sm-3">

                                            <div class="card">
                                                <div class="filter">
                                                    <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">As of Today</a>

                                                </div>

                                                <div class="card-body">
                                                    <h3 class="card-title">Bank Accounts</h3>
                                                    <div className="dashboard-vert-scrl-wrapper" id="dash-style">
                                                        <div class="news">
                                                            <ul>
                                                                <li>
                                                                    <table class="table table-borderless">
                                                                        <tr>
                                                                            <td>
                                                                                <h4>1201 Barclays Bank 3323940...</h4>
                                                                                <p>Bank Balance</p>
                                                                                <p>Quick Book</p>
                                                                            </td>
                                                                            <td>
                                                                                <p>£5</p>
                                                                                <p>£2,842,423,78</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr><td colSpan={2}>Updated 45 days ago</td></tr>
                                                                    </table>
                                                                </li>

                                                                <li>

                                                                    <table class="table table-borderless">
                                                                        <tr>
                                                                            <td>
                                                                                <h4>1201 Barclays Bank 3323940...</h4>
                                                                                <p>Bank Balance</p>
                                                                                <p>Quick Book</p>
                                                                            </td>
                                                                            <td>
                                                                                <p>£5</p>
                                                                                <p>£2,842,423,78</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr><td colSpan={2}>Updated 45 days ago</td></tr>
                                                                    </table>
                                                                </li>

                                                                <li>

                                                                    <table class="table table-borderless">
                                                                        <tr>
                                                                            <td>
                                                                                <h4>1201 Barclays Bank 3323940...</h4>
                                                                                <p>Bank Balance</p>
                                                                                <p>Quick Book</p>
                                                                            </td>
                                                                            <td>
                                                                                <p>£5</p>
                                                                                <p>£2,842,423,78</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr><td colSpan={2}>Updated 45 days ago</td></tr>
                                                                    </table>
                                                                </li>

                                                                <li>

                                                                    <table class="table table-borderless">
                                                                        <tr>
                                                                            <td>
                                                                                <h4>1201 Barclays Bank 3323940...</h4>
                                                                                <p>Bank Balance</p>
                                                                                <p>Quick Book</p>
                                                                            </td>
                                                                            <td>
                                                                                <p>£5</p>
                                                                                <p>£2,842,423,78</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr><td colSpan={2}>Updated 45 days ago</td></tr>
                                                                    </table>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 col-sm-3">
                                            <div class="card">
                                                <div class="filter">
                                                    <a class="icon" href="#" data-bs-toggle="dropdown">Last Month <i class="bi bi-chevron-down"></i></a>
                                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li class="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>

                                                        <li><a class="dropdown-item" href="#">Today</a></li>
                                                        <li><a class="dropdown-item" href="#">This Month</a></li>
                                                        <li><a class="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>

                                                <div class="card-body">
                                                    <h3 class="card-title">Profit & Loss</h3>

                                                    <div class="news">
                                                        <p>Quia quae rerum explicabo officiis beatae</p>
                                                        <h6>£ 882, 911 <span><i class="bi bi-exclamation"></i> 100</span></h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-12 col-lg-6 col-sm-6"><div class="card">
                                            <div class="filter">
                                                <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                                                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                    <li class="dropdown-header text-start">
                                                        <h6>Filter</h6>
                                                    </li>

                                                    <li><a class="dropdown-item" href="#">Today</a></li>
                                                    <li><a class="dropdown-item" href="#">This Month</a></li>
                                                    <li><a class="dropdown-item" href="#">This Year</a></li>
                                                </ul>
                                            </div>

                                            <div class="card-body">
                                                <h3 class="card-title">Tasks</h3>
                                                <div className="dashboard-vert-scrl-wrapper" id="dash-style">
                                                    <div class="news">
                                                        <ul>
                                                            <li>
                                                                <table class="table table-borderless">
                                                                    <tr>
                                                                        <td>
                                                                            <h4>1201 Barclays Bank 3323940...</h4>
                                                                            <p>Bank Balance</p>
                                                                            <p>Quick Book</p>
                                                                        </td>
                                                                        <td><span class="badge bg-warning">Pending</span></td>
                                                                        <td>
                                                                            
                                                                           £2,842,423,78
                                                                        </td>
                                                                    </tr>
                                                                   
                                                                </table>
                                                            </li>


                                                            <li>
                                                                <table class="table table-borderless">
                                                                    <tr>
                                                                        <td>
                                                                            <h4>1201 Barclays Bank 3323940...</h4>
                                                                            <p>Bank Balance</p>
                                                                            <p>Quick Book</p>
                                                                        </td>
                                                                        <td><span class="badge bg-warning">Pending</span></td>
                                                                        <td>
                                                                            
                                                                           £2,842,423,78
                                                                        </td>
                                                                    </tr>
                                                                   
                                                                </table>
                                                            </li>


                                                            <li>
                                                                <table class="table table-borderless">
                                                                    <tr>
                                                                        <td>
                                                                            <h4>1201 Barclays Bank 3323940...</h4>
                                                                            <p>Bank Balance</p>
                                                                            <p>Quick Book</p>
                                                                        </td>
                                                                        <td><span class="badge bg-warning">Pending</span></td>
                                                                        <td>
                                                                            
                                                                           £2,842,423,78
                                                                        </td>
                                                                    </tr>
                                                                   
                                                                </table>
                                                            </li>


                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div></div>
                                        <div className="col-12 col-lg-3 col-sm-3">
                                            <div class="card">
                                                <div class="filter">
                                                    <a class="icon" href="#" data-bs-toggle="dropdown">Last 30 Days <i class="bi bi-chevron-down"></i></a>
                                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li class="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>

                                                        <li><a class="dropdown-item" href="#">Today</a></li>
                                                        <li><a class="dropdown-item" href="#">This Month</a></li>
                                                        <li><a class="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>

                                                <div class="card-body">
                                                    <h3 class="card-title">Expenses</h3>

                                                    <div class="news">
                                                        <p>Quia quae rerum explicabo officiis beatae</p>
                                                        <h6>£ 882, 911 <span><i class="bi bi-exclamation"></i> 100</span></h6>

                                                        
                                                    </div>

                                                </div>
                                            </div>
                                            </div>
                                        <div className="col-12 col-lg-3 col-sm-3">

                                        <div class="card">
                                                <div class="filter">
                                                    <a class="icon" href="#" data-bs-toggle="dropdown">Last 30 Days <i class="bi bi-chevron-down"></i></a>
                                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li class="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>

                                                        <li><a class="dropdown-item" href="#">Today</a></li>
                                                        <li><a class="dropdown-item" href="#">This Month</a></li>
                                                        <li><a class="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>

                                                <div class="card-body">
                                                    <h3 class="card-title">Expenses</h3>

                                                    <div class="news">
                                                        <p>Quia quae rerum explicabo officiis beatae</p>
                                                        <h6>£ 882, 911 <span><i class="bi bi-exclamation"></i> 100</span></h6>

                                                        
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="card">
                                            

                                            <div class="card-body">
                                                    <h3 class="card-title">Cash Flow Trend</h3>

                                                    <div class="news">
                                                        <p>Quia quae rerum explicabo officiis beatae</p>
                                                        <h6>£ 882, 911 <span><i class="bi bi-exclamation"></i> 100</span></h6>

                                                        
                                                    </div>

                                                </div>
                                        </div></div>
                                        <div className="col-12 col-lg-3 col-sm-3">
                                            <div class="card">
                                                <div class="filter">
                                                    <a class="icon" href="#" data-bs-toggle="dropdown">Last 30 Days <i class="bi bi-chevron-down"></i></a>
                                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li class="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>

                                                        <li><a class="dropdown-item" href="#">Today</a></li>
                                                        <li><a class="dropdown-item" href="#">This Month</a></li>
                                                        <li><a class="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>

                                                <div class="card-body">
                                                    <h3 class="card-title">Expenses</h3>

                                                    <div class="news">
                                                        <p>Quia quae rerum explicabo officiis beatae</p>
                                                        <h6>£ 882, 911 <span><i class="bi bi-exclamation"></i> 100</span></h6>

                                                        
                                                    </div>

                                                </div>
                                            </div>
                                            </div>
                                        <div className="col-12 col-lg-3 col-sm-3">

                                        &nbsp;
                                        </div>
                                    </div>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Dashboard;
