import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Master/ViewProduct';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { fetchCustomerData, handleCustomerChange, fetchWarehouseData, handleProductAdd, handleProductAddNew, handleSalesProductAddNew, getDeliveryAddrById, setBatchToProduct, setSalesBatchToProduct, setSalesBatchToProductNew, handleBatchChange, handleSalesBatchChange, handleSalesBatchChangeNew } from '../../services/NewSalesService';
import { fetchProductData, removeRow, handleInputChangeInSales, handleInputChangeInSalesNew } from '../../services/NewPurchaseOrderService';

 

const NewSalesOrder = () => {
  const accessToken = localStorage.getItem('accessToken');
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [inputDeliveryAddresses, setInputDeliveryAddresses] = useState([]);
  const [deliveryAddrDropdown, setDeliveryAddrDropdown] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);
  const [netWeight, setNetWeight] = useState(0);
  const [cube, setCube] = useState(0);
  const [productBatch, setProductBatch] = useState([]);
  const [grossProfit, setGrossProfit] = useState(0);
  const [grossProfitPercent, setGrossProfitPercent] = useState(0);
  const navigateToOrderHistory = useNavigate();
  const productBatchRef = useRef(null);
  const [modalData, setModalData] = useState(null);
  const CreatePOModal = useRef(null);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [selectedpurchaseId, setSelectedpurchaseId] = useState(null);
  const [selectedUnallocation, setSelectedUnallocation] = useState(null);
  const [selectedCost, setSelectedCost] = useState(0);
  const AssignBatchModal = useRef(null);

  const [totalVal, setTotalVal] = useState({
    stdGoods: 0,
    zeroGoods: 0,
    vatAmt: 0,
    grandTotal: 0,
    totalItems : 0,
    grossWeight : 0,
    netWeight : 0,
    cbm : 0,
    totalProfitPercent : 0,
    totalProfit : 0,
    totalProfit1 :0,
  });

  const [deliveryType, setDeliveryType] = useState('delivery');
  const [inputCollectionAddresses, setInputCollectionAddresses] = useState({
    address_line1: '',
    address_line2: '',
    city: '',
    country: '',
    post_code: '',
  });

  

  const handleDeliveryandCollectionAddress = (e) => {
    setDeliveryType(e.target.value);
    setInputDeliveryAddresses([]);
  };


  useEffect(() => {
    const calculateTotals = () => {
      const stdGoods = tableRows
        .filter(item => item.vat != '0.00' && item.vat != null)
        .reduce((sum, item) => sum + (parseFloat(item.price) * parseFloat(item.pdtqty)), 0);

      const zeroGoods = tableRows
        .filter(item => item.vat == '0.00' || item.vat == null)
        .reduce((sum, item) => sum + (parseFloat(item.price) * parseFloat(item.pdtqty)), 0);

      const vatAmt = tableRows.reduce((sum, item) => {
        const itemVat = (parseFloat(item.price) * parseFloat(item.pdtqty) * (parseFloat(item.vat) / 100)) || 0;
        return sum + itemVat;
      }, 0);

      const grandTotal = stdGoods + zeroGoods + vatAmt;

      const totalItems = tableRows.reduce((sum, item)=>{
        const itemCount = parseInt(item.pdtqty);
        return sum + itemCount;
      }, 0);

      const grossWeight = tableRows.reduce((sum, item) => {
        const grossWeightCount = parseInt(item.pdtqty) * parseFloat(item.grossWeight);
        return sum + grossWeightCount;
      }, 0);

      const netWeight = tableRows.reduce((sum, item) => {
        const netWeightCount = parseInt(item.pdtqty) * parseFloat(item.netWeight);
        return sum + netWeightCount;
      }, 0);

      const cbm = tableRows.reduce((sum, item) => {
        const cbmCount = parseInt(item.pdtqty) * parseFloat(item.cbm);
        return sum + cbmCount;
      }, 0);

     


let totalProfit1 = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * row.pdtqty), 0);

// Calculate the total revenue to find the profit percentage
let totalRevenue = tableRows.reduce((revenue, row) => revenue + (row.price * row.pdtqty), 0);

// To prevent division by zero
let totalProfitPercent = totalRevenue > 0 ? (totalProfit1 / totalRevenue) * 100 : 0;


const totalProfit = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.pdtqty)), 0);
      //setGrossProfit(totalProfit);



      setTotalVal({
        stdGoods,
        zeroGoods,
        vatAmt,
        grandTotal,
        totalItems,
        grossWeight,
        netWeight,
        cbm,
        totalProfit,
        totalProfitPercent,
      });
    };

    // Call the function to calculate totals whenever tableRows changes
    calculateTotals();
  }, [tableRows]);

  const closeAssignBatchModal = (event) => {
    if (AssignBatchModal.current) {
      const bsModal = window.bootstrap.Modal.getInstance(AssignBatchModal.current);
      if (bsModal) bsModal.hide();
    }
  }

  const [hasMappedPO, setHasMappedPO] = useState(true);

  const handleBatchAssignementSubmit = (e) => {

     if (productBatch.length === 0) {
      console.error("No batches available."); // Log an error message
      return; // Exit the function early
    }
    //const itemId = parseInt(e.target.value);
    const itemId = productBatch[0].product_id;




    

    const selectedItem = productBatch.find(item => item.id === itemId);

    setSalesBatchToProductNew(productBatch[0].product_id,selectedBatchId, selectedpurchaseId, setTableRows, selectedCost);
    closeAssignBatchModal();


    
    const checkMapped = tableRows.some(row => row.mappedPO !== '');
    setHasMappedPO(checkMapped);
      


  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedBatchId) {

      fetch(`${config.api_url}/ws-batch-allocation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedBatchId }),
      })
        .then(response => response.json())
        .then(data => {

        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      alert("Please select a batch before submitting.");
    }
  };

  const handleOpenPastPurchaseModal = (data) => {
    setModalData(data);
    if (productBatchRef.current) {
      productBatchRef.current();

    }
  };

  useEffect(() => {
    $('.data').hide();

    $('button').on('click', function (event) {
      event.preventDefault(); // Prevent the default action of the button
      $('.data').toggle();
    });

    // Cleanup function to avoid memory leaks
    return () => {
      $('button').off('click'); // Unbind the event when the component unmounts
    };
  }, []); // Make sure to add the empty dependency array to run it only once



  useEffect(() => {
    fetchProductData(setProductData);
    fetchCustomerData(setCustomerData);
    fetchWarehouseData(setWarehouseData);
  }, []);

  /*useEffect(() => {
    const totalItem = tableRows.reduce((total, row) => {
      if (parseInt(row.quantity) > 0) {
        return total + parseInt(row.quantity);
      }
      return total;
    }, 0);
    setTotalItems(totalItem);

    const newGrandTotal = tableRows.reduce((total, row) => total + row.value, 0);
    setGrandTotal(newGrandTotal);

    const newGrossTotal = tableRows.reduce((total, row) => total + parseFloat(row.grossWeight), 0);
    setGrossWeight(newGrossTotal);

    const totalVat = tableRows.reduce((vat, row) => vat + row.vat, 0);
    setVatAmt(totalVat);

    const totalProfit = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.quantity)), 0);
    setGrossProfit(totalProfit);

    let totalProfitPercent = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.quantity)), 0);
    totalProfitPercent = totalProfitPercent / (tableRows.length);
    setGrossProfitPercent(totalProfitPercent);

    const stdGoods = tableRows.reduce((total, row) => {
      console.log("calculate std goods");
      if (parseFloat(row.vat) > 0) {
        return total + row.value;
      }
      return total;
    }, 0);

    setStdGoods(stdGoods);

    const zeroGoods = tableRows.reduce((total, row) => total + row.value, 0);
    setZeroGoods(zeroGoods);

    const netWeightTotal = tableRows.reduce((total, row) => total + parseFloat(row.netWeight), 0);
    setNetWeight(netWeightTotal);

    const netCBM = tableRows.reduce((total, row) => total + parseFloat(row.cbm), 0);
    setCube(netCBM);

  }, [tableRows]);*/


  const getProductBatchData = async (id, batch_id) => {

    setProductBatch([]);

    try {
      const formData = new FormData();
      formData.set('product_id', id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-id`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );


      const data = response.data;

      console.log(data.batch);

      const updatedBatch = data.batch.map(item => ({
        ...item,
        batch_id: batch_id // Add the batch_id to each item
      }));

      setProductBatch(updatedBatch);
      handleOpenPastPurchaseModal(data.batch);
      setSelectedBatchId(batch_id);

      //selectedBatchId

    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const submitSalesOrderForm = async (type) => {

    

    const form = document.getElementById('new_sales_order');
    const formData = new FormData(form);
    let save_status = formData.get('save_as_proforma');
    let delivery_date = formData.get('delivery_date');

    let customerId = formData.get('customer_id'); 
    if (customerId == '') {
      Swal.fire({
        title: "Failed!",
        text: "Please Select Customer.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

   const hasValidProduct = tableRows.some((row) => 
   {
      const quantity = parseFloat(row.quantity);
      return !isNaN(quantity) && quantity > 0;
    });

    // if (!tableRows.length || !hasValidProduct) 
    // {
    //   Swal.fire({
    //     title: "Error!",
    //     text: "Please select at least one product and ensure the quantity is greater than zero.",
    //     icon: "error",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    //   return;
    // }




    if (delivery_date == '') {
      formData.set('delivery_date', '0000-00-00');
    }

    let status = 'SO';
    if (save_status == 'on') {
      status = 'PF';
    }
    
    formData.append(`status`, status);
    formData.append(`reqType`, type);
    if (tableRows.length === 0) {
      Swal.fire({
        title: "Failed!",
        text: "Please Select Product.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }else{
      // Manually append the table rows data
      tableRows.forEach((row, index) => {
        formData.append(`code[${index}]`, row.code);
        formData.append(`packsize[${index}]`, row.pack);
        formData.append(`productSize[${index}]`, row.size);
        formData.append(`qty[${index}]`, row.pdtqty);
        formData.append(`price[${index}]`, row.price);
        formData.append(`cost[${index}]`, row.cost);
        formData.append(`value[${index}]`, row.value);
        formData.append(`Vat[${index}]`, row.vat);
        formData.append(`onSO[${index}]`, row.onSO !== undefined ? row.onSO : 0);
        formData.append(`fstk[${index}]`, row.fstk !== undefined ? row.fstk : 0);
        formData.append(`product_id[${index}]`, row.id !== undefined ? row.id : 0);
        formData.append(`batch_id[${index}]`, row.batch_id !== undefined ? row.batch_id : 0);
        formData.append(`purchase_id[${index}]`, row.purchase_id !== undefined ? row.purchase_id : 0);
        formData.append(`subtotal[${index}]`, row.value !== undefined ? row.value : 0);
        
      });
    }

    try {
      const response = await axios.post(`${config.api_url}/ws-sales-addLatest`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });

      const result = response.data;

      console.log(response.data);

      if (result.status === 'success') {
        Swal.fire({
          title: "Success!",
          text: "Order submitted successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          if (type === 'createPO') {
            navigateToOrderHistory(`/CreatePO/${result.data}`);
          } else if (type === 'leterPO') {
            navigateToOrderHistory('/CurrentSalesOrder');
          }
        });

      }else{
        Swal.fire({
          title: "Failed!",
          text: "Sales Order Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }

      if (CreatePOModal.current) {
        const bsModal = window.bootstrap.Modal.getInstance(CreatePOModal.current);
        if (bsModal) bsModal.hide();
      }

      


      


    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  useEffect(() => {
    if (deliveryAddresses.length > 0) {
      //console.log("Delivery addresses have been updated:", deliveryAddresses);
    }
  }, [deliveryAddresses]);

  

  return (
    <div>

      <div className="modal fade" id="save-sales" tabIndex="-1" ref={CreatePOModal}>
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                

                 {/* hasMappedPO ? (
                    <h3> Do you want to create a P.O?</h3>
                  ) : (
                    <h3>Submit S.O?</h3>
                  ) */}

                    <h3> Do you want to create a P.O?</h3>

                <hr />
                {/* hasMappedPO ? (
                <Link to='#' data-bs-dismiss="modal" aria-label="Close" className="popup-innerbtn-common"  onClick={() => submitSalesOrderForm('createPO')} >Yes</Link>
                ) : '' */}

                <Link to='#' data-bs-dismiss="modal" aria-label="Close" className="popup-innerbtn-common"  onClick={() => submitSalesOrderForm('createPO')} >Yes</Link>
                <Link to='#' onClick={() => submitSalesOrderForm('leterPO')}   data-bs-dismiss="modal" aria-label="Close" className="popup-innerbtn-closebtn">Later</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewProduct />

      <div className="modal fade" id="viw-pst-invs" tabIndex="-1" ref={AssignBatchModal}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">All Batches :  {productBatch[0]?.product_desc}  ({productBatch[0]?.code})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <h3>
                  <a href='#' data-bs-toggle="modal" data-bs-target="#vw-pst-ordr">View Past Invoices</a></h3>
                <form id='selectBatchForm'>
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        
                        <th>Stock Qty</th>
                        <th>Sold Qty</th>
                        <th>Bal Qty</th>
                        <th>Alloc</th>
                        <th>UnAlloc</th>
                        <th>PO No.</th>
                        <th>Net Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productBatch.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="radio"
                              id={`batch_${item.id}`}
                              name="select_batch"
                              value={item.id}
                              data-purchaseId={item.purchase_id}
                              data-unallocation={item.qty - item.allocation}
                              data-cost={item.cost_price}
                              onChange={(e) => handleSalesBatchChangeNew(e, setSelectedBatchId, setSelectedpurchaseId, setSelectedCost)}
                              checked={selectedBatchId  === item.id} // Check if this item is the selected one
                            />
                            <label htmlFor={`batch_${item.id}`}>Select PO</label>
                          </td>
                        
                          <td>{item.qty}</td>
                          <td>{item.sale_qty}</td>
                          <td>{item.qty-item.sale_qty}</td>
                          <td>{item.allocation}</td>
                          <td>{item.qty-item.allocation}</td>
                          <td>
                            <a href="#" target="_blank" rel="noopener noreferrer">
                              {item.reference_no}
                            </a>
                          </td>
                          <td>{item.cost_price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <a className="popup-innerbtn-common" data-bs-dismiss="modal" aria-label="Close" onClick={handleBatchAssignementSubmit} style={{ marginTop: '10px' }} > Submit</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="vw-pst-ordr" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;All Invoices of Product : CIF CREAM LEMON (CIF016)</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>SO#</th>
                      <th>Qty</th>
                      <th>Price </th>
                      <th>Delivered on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>12660</td>
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>
                    <tr>
                      <td>12660</td>
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>
                    <tr>
                      <td>12660</td>
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>
                    <tr>
                      <td>12660</td>
                      <td>1225</td>
                      <td>9.93</td>
                      <td>02/08/24</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="adnw-prct" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <h3>Search (Code/Description/Unit Barcode)</h3>
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Description</th>
                      <th>PK</th>
                      <th>Size</th>
                      <th>Unit Barcode</th>
                      <th>Stock</th>
                      <th>F.Stk</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><a href="#">SKI010</a></td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                    <tr>
                      <td><a href="#">SKI010</a></td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                    <tr>
                      <td><a href="#">SKI010</a></td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                    <tr>
                      <td><a href="#">SKI010</a></td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">New Sales Order</span> </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <form id='new_sales_order'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <div className="show-order-area dashboard">
                  <div style={{ marginBottom: '20px', float: 'left' }}>
                    <div className="topsearch-area">
                      <div className="row">
                        <div className="col-12 col-lg-4 col-sm-4">
                          <div className="row">
                            <div className="row">
                              <label htmlFor="inputText" className="col-sm-3 col-form-label">Customer:</label>
                              <div className="col-sm-7">
                                <Select
                                  isClearable
                                  name="customer_id"
                                  onChange={(e) => handleCustomerChange(e, setInvoiceAddress, setDeliveryAddrDropdown, setDeliveryAddresses, setInputDeliveryAddresses)}
                                  options={customerData}
                                  placeholder="Select Customer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-8 col-sm-8">
                          <div style={{ float: "right" }}>
                            <p><label className="checkbox"> <input type="checkbox" name='save_as_proforma' /> <span className="default"></span> </label> <span>Save as Proforma</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="newsalesorderform">
                      <div className="row mb-3 padd-l-13px">
                        <div className="col-6 col-lg-6 col-sm-6">
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label"><strong>Invoice Address *</strong></label>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Address Line 1 </label>
                            </div>
                            <div className="col-8 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" id="addr1" value={invoiceAddress.billing_address_line1} readOnly />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Address Line 2 </label>
                            </div>
                            <div className="col-8 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" id="addr2" value={invoiceAddress.billing_address_line2} readOnly />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">City </label>
                            </div>
                            <div className="col-8 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" value={invoiceAddress.billing_city} readOnly />
                            </div>

                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Country </label>
                            </div>
                            <div className="col-8 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" value={invoiceAddress.billing_country} readOnly />
                            </div>
                          </div>
                          
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Post Code </label>
                            </div>
                            <div className="col-8 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" value={invoiceAddress.billing_postcode} readOnly />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">VAT </label>
                            </div>
                            <div className="col-8 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" readOnly />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Warehouse </label>
                            </div>
                            <div className="col-8 col-lg-9 col-sm-9">
                              <Select
                                name='warehouse_id'
                                id='warehouse_id'
                                isClearable
                                options={warehouseData}
                                placeholder="Select Warehouse" className='abovedd'
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6 col-lg-6 col-sm-6">

                          <div className="row mb-3">
                            <div className="col-3 col-lg-3 col-sm-3">
                              <label className="col-form-label padd-left-50">
                                Customer Address
                                </label>
                            </div>
                            <div className="col-1 col-lg-1 col-sm-1" style={{paddingTop: '8px'}}>

                              <input
                              type="radio"
                              name="delivery_type"
                              id="delivery_type"
                              value="delivery"
                               defaultChecked
                              onChange={(e) =>handleDeliveryandCollectionAddress(e)}  style={{ float: "left" }}
                              />
                            </div>

                          
                            <div className="col-3 col-lg-3 col-sm-3">
                              <label className="col-form-label padd-left-50">
                                Collection Address
                                </label>
                            </div>
                            <div className="col-3 col-lg-3 col-sm-3" style={{paddingTop: '8px'}}>

                              <input
                              type="radio"
                              name="delivery_type"
                              id="delivery_type"
                              value="collection"
                              onChange={(e) =>handleDeliveryandCollectionAddress(e)}  style={{ float: "left" }}
                              />
                            </div>

                          </div>
                          {deliveryType === 'delivery' && (
                            <div>
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label"><strong>Delivery Address </strong></label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <select name="delivery_id" onChange={(e) => getDeliveryAddrById(e, deliveryAddresses, setInputDeliveryAddresses)} className='form-select orgselect'>
                                    <option>Select</option>
                                    {deliveryAddrDropdown.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.address_line1}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc"  className="col-form-label">Address Line 1 </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input type="text" name="addr1" className="form-control" readOnly value={inputDeliveryAddresses.address_line1} />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Address Line 2 </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input type="text" name="addr2" className="form-control" readOnly value={inputDeliveryAddresses.address_line2} />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">City </label>
                                </div>
                                <div className="col-8 col-lg-3 col-sm-3">
                                  <input type="text" name="city" className="form-control" readOnly value={inputDeliveryAddresses.city} />
                                </div>

                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Country </label>
                                </div>
                                <div className="col-8 col-lg-3 col-sm-3">
                                  <input type="text" name="country" className="form-control" readOnly value={inputDeliveryAddresses.country} />
                                </div>
                              </div>
                              
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Post Code </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input type="text" name="post_code" className="form-control" readOnly value={inputDeliveryAddresses.post_code} />
                                </div>
                              </div>
                          </div>
                          )}


                          {deliveryType === 'collection' && (
                            <div>
                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">Address Line 1 </label>
                                </div>
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="addr1"
                                    className="form-control"
                                    value={inputCollectionAddresses.address_line1}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, address_line1: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">Address Line 2 </label>
                                </div>
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="addr2"
                                    className="form-control"
                                    value={inputCollectionAddresses.address_line2}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, address_line2: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">City </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    value={inputCollectionAddresses.city}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, city: e.target.value })}
                                  />
                                </div>

                                <div className="col-3">
                                  <label className="col-form-label">Country </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    value={inputCollectionAddresses.country}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, country: e.target.value })}
                                  />
                                </div>
                              </div>
                              
                              <div className="row mb-3">
                              <div className="col-4 col-lg-3 col-sm-3">
                                  <label className="col-form-label">Post Code </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input
                                    type="text"
                                    name="post_code"
                                    className="form-control"
                                    value={inputCollectionAddresses.post_code}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, post_code: e.target.value })}
                                  />
                                </div>
                              </div>



                              
                            </div>
                          )}

                          
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Delivery Date </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="date" className="form-control" name="delivery_date" id="delivery_date" />
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Delivery Time </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="time" className="form-control" name="delivery_time" id="delivery_time" />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Customer Ref </label>
                            </div>
                            <div className="col-8 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" name="customer_ref" id="customer_ref" autoComplete='off' />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Job Ref </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" name="job_ref" id="job_ref" autoComplete='off' />
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Booking Ref </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" name="booking_ref" id="booking_ref" autoComplete='off' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    



                   <div className="topsearch-area">
                          <div className="row">
                          <div className="col-12 col-lg-9 col-sm-9">&nbsp;</div>
                            <div className="col-12 col-lg-3 col-sm-3">
                            <div className="col-12 col-lg-12 col-sm-12">
                              <div className='row'>
                                <label htmlFor="inputText" className=" col-form-label col-12 col-lg-4 col-sm-4" >
                                  Product:
                                </label>
                                <div className="col-12 col-lg-8 col-sm-8">
                                <Select
                              isClearable
                              onChange={(e) => handleSalesProductAddNew(e, tableRows, setTableRows)}
                              options={productData}
                              placeholder="Select Product"
                            />
                                </div>
                                </div>
                               
                                </div>

                            </div>



                          </div>
                        </div>



                    <div className="verical-scrl-wrapper common-table newsalesorder-table" id="style-3">
                      <table className="table table-bordered dataTable" id="dsTable">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Pk</th>
                            <th>Size</th>
                            <th style={{ width: '15px' }}>Case Qty</th>
                            <th>Price</th>
                            <th>Cost</th>
                            <th>Value</th>
                            <th>Profit</th>
                            <th>VAT</th>
                            <th>Stock</th>
                            <th>On PO</th>
                            <th>F.Stk</th>
                            <th>Mapped P.O No.</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableRows.length === 0 ? (
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan={15}>No product added</td>
                            </tr>
                          ) : (
                            tableRows.map((row) => (
                              <tr key={row.id}>
                                <td>
                                  <input type="hidden" name="product_id[]" value={row.id} />
                                  <input type="hidden" name="code[]" value={row.code} />
                                  <input type="hidden" name="batch_id[]" value={row.batch_id} />
                                  <input type="hidden" name="purchase_id[]" value={row.purchase_id} />
                                  
                                  <Link
                                    to="#"
                                    onClick={() => getProductBatchData(row.id, row.batch_id)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#viw-pst-invs"
                                  >
                                    {row.code}
                                  </Link>
                                </td>
                                <td>
                                  <p className="cut-text">
                                    <a
                                      data-bs-toggle="modal"
                                      className="applybtn"
                                      data-id={row.id}
                                      data-bs-target="#viewproduct"
                                    >
                                      {row.product_desc}
                                    </a>
                                  </p>
                                </td>
                                <td>
                                  <input type="hidden" name="packsize[]" value={row.pack} /> {row.pack}
                                </td>
                                <td>
                                  <input type="hidden" name="productSize[]" value={row.size} /> {row.size}
                                </td>
                                <td style={{ width: '20px' }}>
                                  <input
                                    type="number"
                                    name="qty[]"
                                    className="form-control qtyinputbox"
                                    onChange={(e) => handleInputChangeInSalesNew(e, row.id, "pdtqty", setTableRows)}
                                    autoComplete='off'
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="price[]"
                                    className="form-control qtyinputbox"
                                    value={row.price}
                                    onChange={(e) => handleInputChangeInSalesNew(e, row.id, "price", setTableRows)}
                                     step="0.01" // Allow decimal input
                                    autoComplete='off'
                                  />
                                                                  </td>
                                <td style={{ textAlign: 'right' }}>
                                  <input
                                    type="number"
                                    name="cost[]"
                                    className="form-control qtyinputbox"
                                    value={row.cost}
                                    onChange={(e) => handleInputChangeInSalesNew(e, row.id, "cost", setTableRows)}
                                    step="0.01" // Allow decimal input
                                    autoComplete='off'
                                  />
                                </td>
                                <td>{row.value.toFixed(2)}</td>
                                <td style={{ textAlign: 'right' }}>
                                  <input type="hidden" name="profit[]" value={((row.price - row.cost) * (row.pdtqty)).toFixed(2)} />
                                  {((row.price - row.cost) * (row.pdtqty)).toFixed(2)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <input type="hidden" name="vat[]" value={((row.value / 100) * row.vat).toFixed(2)} />
                                  {((row.value / 100) * row.vat).toFixed(2)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <input type="hidden" name="stock[]" value={row.quantity} />
                                  {row.quantity}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <input type="hidden" name="onPO[]" value="0" />
                                 {/*row.Onpo*/}
                                  {row.onpurchase}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <input type="hidden" name="onSO[]" value="0" />
                                    {/* 
                                      
                                      ((Number(row.quantity) || 0) + (Number(row.Onpo) || 0)) - (Number(row.allocation))
                                    */}
                                    {/*(Number(row.freestock)) + (Number(row.quantity)) */}

                                    {row.freestockcal }
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <input type="hidden" name="fstk[]" value="0" />
                                   {
                                      row.batch_id != 0 ? (
                                        <span>PO{row.purchase_id}</span>
                                      ) : (
                                        <span>{row.mappedPO ? row.mappedPO : ''}</span>
                                      )
                                    }
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() => removeRow(row.id, setTableRows)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </td>
                                <input type="hidden" name="stdGoods" value={totalVal.stdGoods.toFixed(2)} />
                                <input type="hidden" name="vatAmt" value={totalVal.vatAmt.toFixed(2)} />
                                <input type="hidden" name="zeroGoods" value={totalVal.zeroGoods.toFixed(2)} />
                                <input type="hidden" name="grandTotal" value={totalVal.grandTotal.toFixed(2)} />
                                <input type="hidden" name="totalItems" value={totalVal.totalItems} />
                                <input type="hidden" name="grossWeight" value={totalVal.grossWeight} />
                                <input type="hidden" name="netWeight" value={totalVal.netWeight} />
                                <input type="hidden" name="cube" value={totalVal.cbm} />
                                <input type="hidden" name="grossProfit" value={totalVal.totalProfit.toFixed(2)} />
                                <input type="hidden" name="grossProfitPercent" value={totalVal.totalProfitPercent.toFixed(2)} />
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="newsalesorderftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">Std Rated : {totalVal.stdGoods.toFixed(2)} </div>
                        <div className="col-6 col-lg-3 col-sm-3">VAT Amount : {totalVal.vatAmt.toFixed(2)} </div>
                        <div className="col-6 col-lg-3 col-sm-3">Zero Rated : {totalVal.zeroGoods.toFixed(2)}</div>
                        <div className="col-6 col-lg-3 col-sm-3">Grand Total : {totalVal.grandTotal.toFixed(2)}</div>
                      </div>
                    </div>
                    <div className="newsalesorderftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">Total Items :  {totalVal.totalItems} </div>
                        <div className="col-6 col-lg-3 col-sm-3">Gross Weight : {totalVal.grossWeight} KGS</div>
                        <div className="col-6 col-lg-3 col-sm-3">Net Weight : {totalVal.netWeight} KGS</div>
                        <div className="col-6 col-lg-3 col-sm-3">Cube : {totalVal.cbm} M3</div>
                      </div>
                    </div>


                    <button className='clickherebtn'>Show Gross Profit</button>
                    <div class="data">
                      <div className="newsalesorderftr">
                        <div className="row">
                          <div className="col-6 col-lg-6 col-sm-6">Gross Profit : {totalVal.totalProfit.toFixed(2)}</div>
                          <div className="col-6 col-lg-6 col-sm-6">Gross Profit % : {totalVal.totalProfitPercent.toFixed(2)}%</div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="row mb-3">
                        <div className="col-6 col-lg-2 col-sm-2">
                          <label htmlFor="desc" className="col-form-label">Notes :</label></div>
                        <div className="col-6 col-lg-4 col-sm-4">
                          <textarea className="form-control" name="notes" id="notes" placeholder="" style={{ height: '100px' }}></textarea>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <a href="#" className="popup-innerbtn-common" data-bs-toggle="modal" data-bs-target="#save-sales">Save Sales Order</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default NewSalesOrder; 
