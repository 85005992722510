import React, { useState, useEffect } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from '../Common_Files/Config';
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const OrderHistory = () => {
  const [reportrange, setReportrange] = useState('');
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [orderHistory, setOrderHistory] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, 'yyyy-MM-dd'));
      setToDate(format(end, 'yyyy-MM-dd'));
      setReportrange(`${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`);
    }
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("searchByFromdate", fromDate);
    formData.append("searchByTodate", toDate);
    formData.append('searchValue', selectedRole);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);

    const apiUrl = `${config.api_url}/ws-get-sales-history`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch sales data");
      }

      const data = await response.json();
      setOrderHistory(data.data);
      // Assuming you need to update some elements with totals
      $("#total_grand_total").text(data.total_grand_total);
      $("#total_grand_total1").text(data.total_grand_total1);
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      data: orderHistory,
      columns: [
      { data: "order_date", title: "Order Date" },
      { 
        data: 'reference_no',
        title: 'Order Id',
        render: data => `${data}`
      },
      { 
        data: "company_name",
        title: "Company Name",
        render: data => `<p class="cut-text">${data}</p>`
      },
      { data: "order_amount", title: "Order Amount" },
      { data: "previous_due", title: "Previous Dues" },
      { data: "status", title: "Status" },
      ],
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
    });

    return () => {
      table.destroy();
    };
  }, [orderHistory]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="dashboard.html">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Order History</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="show-order-area dashboard">
                <form onSubmit={handleSubmit} className="dashform">
                  <div className="topsearch-area d-flex align-items-center">
                    <div className="form-group d-flex align-items-center">
                      <label htmlFor="dateRange" className="col-sm-4 col-form-label mr-2">Date Range</label>
                      <DateRangePicker
                        value={[fromDate ? new Date(fromDate) : undefined, toDate ? new Date(toDate) : undefined]}
                        onChange={handleDateChange}
                        format="MM-dd-yyyy"
                        className="mr-2"
                      />
                    
                    </div>
                  </div>
                </form>
                <div className="verical-scrl-wrapper order-history-table" id="style-3">
                  <table
                    className="table table-bordered dataTable"
                    id="neworderTable"
                  >
                    <thead>
                      <tr>
                        <th>Order Date</th>
                        <th>Order Id</th>
                        <th>Company Name</th>
                        <th>Order Amount</th>
                        <th>Previous Dues</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Data is populated by DataTables */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
  <Footer />
  </div>
  );
};

export default OrderHistory;
