import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import Swal from 'sweetalert2';

const Bank = () => {
  const accessToken = localStorage.getItem('accessToken');
  const modalRef = useRef(null);
  const editModalRef = useRef(null);
  const tableRef = useRef(null); // Reference to the USD table element
  const tableRefEUR = useRef(null); // Reference to the EUR table element
  const tableRefGBP = useRef(null); // Reference to the GBP table element

  const initializeDataTable = (currency) => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
    }
  
    let $table;
  
    if (currency === "USD") {
      $table = $(tableRef.current);
    } else if (currency === "EUR") {
      $table = $(tableRefEUR.current);
    } else if (currency === "GBP") {
      $table = $(tableRefGBP.current);
    }
  
    const searchValue = $('#searchValue').val();
  
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }
  
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.api_url}/ws-bank-list`,
        type: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        data: (d) => ({
          ...d,
          draw: d.draw,
          start: d.start,
          length: d.length,
          searchValue: searchValue,
          currency: currency,
        }),
        dataSrc: (json) => json.bank || []
      },


      columns: [
        { data: 'bank_name',  },
        { data: 'account_name', },
        { data: 'account_no', width: "8%" },
        { data: 'address' },
        { data: 'bank_bic', width: "7%" },
        { data: 'branch_bic', width: "7%" },
        { data: 'sort_code', width: "7%" },
        { data: 'iban', width: "6%" },
        {
          data: null,
          title: 'Status',
          width: "7%",
          render: (data, type, row) => {
            const isChecked = row.status === "1" ? 'checked' : '';
            return `
              <label class="switch">
                <input class="switch-input toggleStatus" type="checkbox" data-id = ${row.id}  name="status" ${isChecked}  />
                <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                <span class="switch-handle"></span>
              </label>`;
          },
          width: '5%'
        },
        {
          data: null,
          title: 'Action',
          width: "7%",
          render: (data, type, row) => `                  
              <a href="#" class="editbtn" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#edit_bank">
                <i class="fa fa-edit"></i>
              </a>`,
          width: '5%'
        }
      ],


      columnDefs: [
        {
          orderable: false,
          targets: '_all'
        }
      ],
      pageLength: 50,
    });
  
    $table.on('click', '.editbtn', function () {
      const id = $(this).data('id');
      handleEditClick(id);
    });

    $table.on('click', '.toggleStatus', function () {
      const id = $(this).data('id');
      const value = this.checked ? 1 : 0; 
      toggleStatus(id, value);
  });
  
    
  };
  

  function toggleStatus(id, isChecked) {
   let formData = new FormData();
   formData.append('id', id);
   formData.append('status', isChecked);
    axios.post(`${config.api_url}/ws-bank-status`, formData)
      .then(response => {
        console.log('Status updated successfully', response);
      })
      .catch(error => {
        console.error('Error updating status', error);
      });
  }


  const handleTabChange = (event) => {
    const currency = $(event.target).text().trim(); 
    $('#selectedCurrency').val(currency);
    $('#selectedEditCurrency').val(currency);
    initializeDataTable(currency);
  };

  useEffect(() => {
    initializeDataTable('EUR');

    // Attach event listeners to the tabs
    $('#myTab button').on('click', handleTabChange);

    return () => {
      if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
      if (tableRefEUR.current && $.fn.DataTable.isDataTable(tableRefEUR.current)) {
        $(tableRefEUR.current).DataTable().destroy();
      }
      if (tableRefGBP.current && $.fn.DataTable.isDataTable(tableRefGBP.current)) {
        $(tableRefGBP.current).DataTable().destroy();
      }
    };
  }, []);


  const handleAddBank = async (e) => {
    e.preventDefault();
    try {
      const formElement = document.getElementById("addBankForm");
      const formData = new FormData(formElement);
    

      const response = await axios.post(
        `${config.api_url}/ws-add-bank`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Bank added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        })
          .then(() => {
            if (modalRef.current) {
              const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
              if (bsModal) bsModal.hide();
            }

            let currency = $('#selectedCurrency').val();
            
            if (currency === "USD") {
              initializeDataTable("USD");
            } else if (currency === "EUR") {
              initializeDataTable("EUR");
            } else if (currency === "GBP") {
              initializeDataTable("GBP");
            }

            document.getElementById("addBankForm").reset();
          })
          .catch((error) => {
            console.error("Error refreshing bank:", error);
          });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message || "An error occurred while adding a bank.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Request Error:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while adding a bank.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleEditBank = async (e) => {
    e.preventDefault();
    try {
      const formElement = document.getElementById("updateBankForm");
      const formData = new FormData(formElement);

      const response = await axios.post(
        `${config.api_url}/ws-update-bank`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Bank updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        })
          .then(() => {
            if (editModalRef.current) {
              const bsModal = window.bootstrap.Modal.getInstance(editModalRef.current);
              if (bsModal) bsModal.hide();
            }

            let currency = $('#selectedEditCurrency').val();
            if (currency === "USD") {
              initializeDataTable("USD");
            } else if (currency === "EUR") {
              initializeDataTable("EUR");
            } else if (currency === "GBP") {
              initializeDataTable("GBP");
            }
            document.getElementById("updateBankForm").reset();
          })
          .catch((error) => {
            console.error("Error refreshing bank:", error);
          });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An error occurred while updating a bank.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Request Error:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating a user.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleEditClick = async (id) => {
    document.getElementById("updateBankForm").reset();
    const formData = new FormData();
    formData.set('bank_id', id);
    const response = await axios.post(`${config.api_url}/ws-get-bank-by-id`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if (response.data.bank) {
      $('#bank_id').val(id);
      $('#bank_name').val(response.data.bank[0].bank_name);
      $('#sort_code').val(response.data.bank[0].sort_code);
      $('#address').val(response.data.bank[0].address);
      $('#bank_bic').val(response.data.bank[0].bank_bic);
      $('#account_name').val(response.data.bank[0].account_name);
      $('#branch_bic').val(response.data.bank[0].branch_bic);
      $('#account_no').val(response.data.bank[0].account_no);
      $('#iban').val(response.data.bank[0].iban);
    }
  };


  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span className="last-crumb">Currency Wise Bank Details</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className='bank-pagearea'>
                <div class="">
                  <div>
                    <div>
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#eur"  type="button" role="tab" aria-controls="eur" aria-selected="true"> EUR </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gbp"  type="button" role="tab" aria-controls="gbp" aria-selected="false"> GBP </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#usd"  type="button" role="tab" aria-controls="usd" aria-selected="false"> USD </button>
                        </li>
                      </ul>
                      <div class="tab-content fulltable-area" id="myTabContent">
                        <div class="tab-pane fade show active" id="eur" role="tabpanel" aria-labelledby="eur-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                {/* <div class="row mb-3">
                                  <label for="inputText" class="col-sm-3 col-form-label">Bank:</label>
                                  <div class="col-sm-6">
                                    <input type="text" className="form-control" name="searchValue" id="searchValue" onChange={initializeDataTable} placeholder="Search Bank" />
                                  </div>
                                </div> */}
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{ float: "right" }}>
                                  <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_bank">Add New Bank</a>
                                </div>
                              </div>
                            </div>
                            <div className="verical-scrl-wrapper" id="style-3">
                              <table className="table table-bordered dataTable" ref={tableRefEUR}>
                                <thead>
                                  <tr>
                                    <th>Bank Name</th>
                                    <th>Account Name</th>
                                    <th>A/C Number </th>
                                    <th>Address</th>
                                    <th>Bank BIC</th>
                                    <th>Branch BIC</th>
                                    <th>Sort Code</th>
                                    <th>IBAN</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="gbp" role="tabpanel" aria-labelledby="gbp-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                {/* <div class="row mb-3">
                                  <label for="inputText" class="col-sm-3 col-form-label">Bank:</label>
                                  <div class="col-sm-6">
                                    <input type="text" className="form-control" name="searchValue" id="searchValue" onChange={initializeDataTable} placeholder="Search Bank" />
                                  </div>
                                </div> */}
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{ float: "right" }}>
                                  <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_bank">Add New Bank</a>
                                </div>
                              </div>
                            </div>

                            <div className="verical-scrl-wrapper" id="style-3">
                              <table className="table table-bordered dataTable" ref={tableRefGBP}>
                                <thead>
                                  <tr>
                                    <th>Bank Name</th>
                                    <th>Account Name</th>
                                    <th>A/C Number </th>
                                    <th>Address</th>
                                    <th>Bank BIC</th>
                                    <th>Branch BIC</th>
                                    <th>Sort Code</th>
                                    <th>IBAN</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="usd" role="tabpanel" aria-labelledby="usd-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                {/* <div class="row mb-3">
                                  <label for="inputText" class="col-sm-3 col-form-label">Bank:</label>
                                  <div class="col-sm-6">
                                    <input type="text" className="form-control" name="searchValue" id="searchValue" onChange={initializeDataTable} placeholder="Search Bank" />
                                  </div>
                                </div> */}
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{  float: "right" }}>
                                  <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_bank">Add New Bank</a>
                                </div>
                              </div>
                            </div>

                            <div className="verical-scrl-wrapper" id="style-3">
                              <table className="table table-bordered dataTable" ref={tableRef}>
                                <thead>
                                  <tr>
                                    <th>Bank Name</th>
                                    <th>Account Name</th>
                                    <th>A/C Number </th>
                                    <th>Address</th>
                                    <th>Bank BIC</th>
                                    <th>Branch BIC</th>
                                    <th>Sort Code</th>
                                    <th>IBAN</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add New bank Modal */}
      <div className="modal fade" id="add_bank" tabIndex="-1" ref={modalRef} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Bank</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body add-product-popup">
              <form id='addBankForm' >
              <input type="hidden" className="form-control" name="currency" id='selectedCurrency' value="EUR" />
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="bank_name" className="col-sm-2 col-form-label">Bank Name *</label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="bank_name" />
                  </div>
                  <label htmlFor="sort_code" className="col-sm-2 col-form-label">Sort Code </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="sort_code" />
                  </div>
                </div>

                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="address" className="col-sm-2 col-form-label">Address </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="address" />
                  </div>
                  <label htmlFor="bank_bic" className="col-sm-2 col-form-label">Bank BIC </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="bank_bic" />
                  </div>
                </div>

                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="account_name" className="col-sm-2 col-form-label">Account Name </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="account_name" />
                  </div>
                  <label htmlFor="branch_bic" className="col-sm-2 col-form-label">Branch BIC </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="branch_bic" />
                  </div>
                </div>

                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="account_no" className="col-sm-2 col-form-label">A/C Number </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="account_no" />
                  </div>
                  <label htmlFor="iban" className="col-sm-2 col-form-label">IBAN </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="iban" />
                  </div>
                </div>

                <hr />
                <div className="row padd-l-13px">
                  <div className="col-sm-12 ">
                    <button type="submit" onClick={handleAddBank} className="popup-innerbtn-common right">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit bank Modal */}
      <div className="modal fade" id="edit_bank" tabIndex="-1" ref={editModalRef} >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Bank</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body add-product-popup">
              <form id='updateBankForm' >
              <input type="hidden" className="form-control" name="currency" id='selectedEditCurrency' value="EUR" />
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="bank_name" className="col-sm-2 col-form-label">Bank Name *</label>
                  <div className="col-sm-4">
                    <input type="hidden" name="bank_id" id='bank_id' />
                    <input type="text" className="form-control" name="bank_name" id='bank_name' />
                  </div>
                  <label htmlFor="sort_code" className="col-sm-2 col-form-label">Sort Code </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="sort_code" id='sort_code' />
                  </div>
                </div>

                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="address" className="col-sm-2 col-form-label">Address </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="address" id='address' />
                  </div>
                  <label htmlFor="bank_bic" className="col-sm-2 col-form-label">Bank BIC </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="bank_bic" id='bank_bic' />
                  </div>
                </div>

                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="account_name" className="col-sm-2 col-form-label">Account Name </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="account_name" id='account_name' />
                  </div>
                  <label htmlFor="branch_bic" className="col-sm-2 col-form-label">Branch BIC </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="branch_bic" id='branch_bic' />
                  </div>
                </div>

                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="account_no" className="col-sm-2 col-form-label">Account Number </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="account_no" id='account_no' />
                  </div>
                  <label htmlFor="iban" className="col-sm-2 col-form-label">IBAN </label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="iban" id='iban' />
                  </div>
                </div>

                <hr />
                <div className="row padd-l-13px">
                  <div className="col-sm-12 ">
                    <button type="submit" onClick={handleEditBank} className="popup-innerbtn-common right">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Bank;
