import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import ViewProduct from "../Master/ViewProduct";
import SupplierSearch from "../Purchase/SupplierSearch";
import ProductBatch from "./ProductBatch";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from "react-router-dom";

//fetchSupplierData
import {
  fetchProductData,
  fetchWarehouseDeliveryData,
  fetchCustomerDeliveryData,
  changeWarehouseAddress,
  changeCustomerAddress,
  handleInputChange,
  handleInputChange_cprice,
  removeRow,
  calculateValue,
  acceptNumber,
  getAddressById,
  handleChange1,
} from "../../services/NewPurchaseOrderService";

const NewPurchaseOrder = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [product, setProduct] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [discTableRows, setDiscTableRows] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [warehouseDeliveryAddress, setWarehouseDeliveryAddress] = useState([]);
  const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [productBatch, setProductBatch] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [netStdGoods, setNetStdGoods] = useState(0);
  const [netVatAmt, setNetVatAmt] = useState(0);
  const [netZeroGoods, setNetZeroGoods] = useState(0);
  const [netGrandTotal, setNetGrandTotal] = useState(0);

  const [productId, setProductId] = useState(0);
  const navigateToGRNList = useNavigate();
  const discModal = useRef(null);
  const [onso, setonso] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState("");
  const [picker, setSelectedProduct] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const productBatchRef = useRef(null);

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');

  const [isDelivery, setIsDelivery] = useState('delivery');
  const [modalData, setModalData] = useState(null);

  const handleOpenPastPurchaseModal = (data) => {
    setModalData(data);
    if (productBatchRef.current) {
      productBatchRef.current();
    }
  };

  const handleDeliveryandCollectionAddress = (event) => {


    setDeliveryAddress({
        name : '',
        code : '',
        address_line1 : '',
        address_line2 : '',
        city:'',
        state:'',
        country:'',
        postcode:'',

        });  
    setCustomerAddress({
        name : '',
        code : '',
        address_line1 : '',
        address_line2 : '',
        city:'',
        state:'',
        country:'',
        postcode:'',

        }); 
    setIsDelivery(event.target.value);
    
  };

  const handleWarehouseandCustomerAddress = (event) => { 

  
    setIsDelivery(event.target.value);
    setDeliveryAddress([]);   
    setCustomerAddress([]);  

  };


  const [formData, setFormData] = useState({
    retro: "",
    promptPayment: "",
    costToServe: "",
    offInvoice: "",
    bedsLeds: "",
    sugroRetro: "",
  });

  const handleDiscount = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleShowPastPurchaseModal = (showModalHandler) => {
    showModalHandler();
  };

  useEffect(() => {
    fetchProductData(setProductData);
    fetchWarehouseDeliveryData(setWarehouseDeliveryAddress);
    fetchCustomerDeliveryData(setCustomerDeliveryAddress);
  }, []);


  const handleSubmit = async (e, tableRows, productId) => {
    e.preventDefault();

    let a = 0;
    const retro = parseFloat(formData.retro) || 0;
    const prompt_payment = parseFloat(formData.promptPayment) || 0;
    const cost_to_serve = parseFloat(formData.costToServe) || 0;
    const off_invoice = parseFloat(formData.offInvoice) || 0;
    const sugroRetro = parseFloat(formData.sugroRetro) || 0;
    const bedsLeds = parseFloat(formData.bedsLeds) || 0;


    let total = retro + prompt_payment + cost_to_serve + off_invoice + sugroRetro + bedsLeds;

    const initializedItems = tableRows.map((item) => {
      if (item.id !== productId || (item.cost) <= total) {
        return item;
      }


      a = 1;
      return {
        ...item,
        netp: (item.quantity * item.cost),
        netprice: (item.cost) - retro - prompt_payment - cost_to_serve - off_invoice - bedsLeds - sugroRetro,
        retro: retro,
        promptPayment: prompt_payment,
        costToServe: cost_to_serve,
        offInvoice: off_invoice,
        bedsLeds: bedsLeds,
        sugroRetro: sugroRetro
      };
    });

    if (a === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please enter discount less than Value",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      setTableRows(initializedItems);
    }

    setFormData({
      retro: "",
      promptPayment: "",
      costToServe: "",
      offInvoice: "",
      bedsLeds: "",
      sugroRetro: "",
    });

    const bsModal = window.bootstrap.Modal.getInstance(discModal.current);
    if (bsModal) bsModal.hide();
  };

  useEffect(() => {


    // const disc = tableRows.reduce((acc, row) => {
    //   const retro = parseFloat(row.retro) || 0;
    //   const promptPayment = parseFloat(row.promptPayment) || 0;
    //   const costToServe = parseFloat(row.costToServe) || 0;
    //   const offInvoice = parseFloat(row.offInvoice) || 0;
    //   const bedsLeds = parseFloat(row.bedsLeds) || 0;
    //   const sugroRetro = parseFloat(row.sugroRetro) || 0;

    //   // Compute the total discount for the row
    //   const discount =  retro + promptPayment + costToServe + offInvoice + bedsLeds + sugroRetro;
    //   return acc + discount;
    // }, 0); 

    // setDiscount(disc);

    // Calculate total VAT
    // const totalVat = tableRows.reduce((total, row) => {

    //   const rowValue = parseFloat(row.netp) || 0;
    //   const vatRate = parseFloat(row.vat) || 0;  
    //   const rowVat = ((rowValue) / 100) * vatRate;  
    //   return total + rowVat ;


    // }, 0); 

    const calculateTotals = () => {

      const totalVat = tableRows.reduce((sum, item) => {
        const itemVat = (parseFloat(item.netp) * (parseFloat(item.vat) / 100)) || 0;
        return sum + itemVat;
      }, 0);

      setVatAmt(totalVat);
     
      const newGrandTotal = tableRows.reduce(
        (total, row) => total + parseFloat(row.netp) || 0,
        0
        );
      setGrandTotal(newGrandTotal + totalVat);

      const stdGoods = tableRows.reduce((total, row) => {
        if (parseFloat(row.vat) > 0) {
          return total + row.netp;
        }
        return total;
      }, 0);

      setStdGoods(stdGoods);

      const zeroGoods = tableRows.reduce((total, row) => {
        if (parseFloat(row.vat) == 0) {
          return total + row.netp;
        }
        return total;
      }, 0);
      setZeroGoods(zeroGoods);



      const totalNetVat = tableRows.reduce((sum, item) => {
        const itemVat = (parseFloat(item.netprice * item.quantity) * (parseFloat(item.vat) / 100)) || 0;
        return sum + itemVat;
      }, 0);

      setNetVatAmt(totalNetVat);
      
      const newNetGrandTotal = tableRows.reduce(
        (total, row) => total + parseFloat(row.netprice) * parseFloat(row.quantity),
        0
        );
      setNetGrandTotal(newNetGrandTotal + totalNetVat);

      const netStdGoods = tableRows.reduce((total, row) => {
        if (parseFloat(row.vat) > 0) {
          return total + row.netprice * row.quantity;
        }
        return total;
      }, 0);
      
      setNetStdGoods(netStdGoods);

      const netZeroGoods = tableRows.reduce((total, row) => {
        if (parseFloat(row.vat) == 0) {
          return total + row.netprice * row.quantity;
        }
        return total;
      }, 0);
      setNetZeroGoods(netZeroGoods);



      // console.log(tableRows);
    };

    calculateTotals();

  }, [tableRows]);



  const handleProductChange = (inputValue) => {
    setSelectedProduct(inputValue);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setProductInputValue(selectedOption);
      handleChange1(selectedOption.value, tableRows, setTableRows);
    } else {
      setProductInputValue(null);
      console.warn("Selected option is invalid or missing a value");
    }
  };

  const getProductBatchData = async (id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-id`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
        );

      const data = response.data;
      setProductBatch(data.batch);
      handleOpenPastPurchaseModal(data.batch);
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };


  const getonpo = async (id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);

      const response = await axios.post(
        `${config.api_url}/ws-customer-wise-sales-order`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
        );

      const data = response.data;
      setonso(data.sales);
      //  handleOpenPastPurchaseModal(data.batch);
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const submitPurchaseOrderForm = async () => {


   // const submitButton = document.getElementById('submitPurchaseOrderForm');
   // submitButton.disabled = true;
    const form = document.getElementById("newPurchaseForm");
    const formData = new FormData(form);
    const supplierId = formData.get("supplier_id");
    const job_ref = formData.get("job_ref");


    // if (job_ref) {
    // } else {
    //   Swal.fire({
    //     title: "Error!",
    //     text: "Job Ref is Required",
    //     icon: "error",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    //   return;
    // }


    if (supplierId) {
    } else {
      Swal.fire({
        title: "Error!",
        text: "Supplier is Required",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    const hasValidProduct = tableRows.some((row) => {
      const quantity = parseFloat(row.quantity);
      return !isNaN(quantity) && quantity > 0;
    });

    if (!tableRows.length || !hasValidProduct) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one product and ensure the quantity is greater than zero.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    tableRows.forEach((row, index) => {
      formData.append(`code[${index}]`, row.code);
      formData.append(`expiryDate[${index}]`, row.expiryDate);
      formData.append(`packsize[${index}]`, row.pack);
      formData.append(`productSize[${index}]`, row.size);
      formData.append(`qty[${index}]`, row.quantity);
      formData.append(`sys_qty[${index}]`, row.sys_quantity);
      formData.append(`price[${index}]`, row.price);
      formData.append(`value[${index}]`, row.value);
      formData.append(`Disc[${index}]`, row.disc);
      formData.append(`NetP[${index}]`, row.netp);
      formData.append(`NetPrice[${index}]`, row.netprice);
      formData.append(`Vat[${index}]`, row.vat);
      formData.append(`currrentStock[${index}]`, row.curStock);
      formData.append(`onSO[${index}]`, row.onSO);
      formData.append(`fstk[${index}]`, row.fstk);

      formData.append(`retro[${index}]`, row.retro);
      formData.append(`promptPayment[${index}]`, row.promptPayment);
      formData.append(`costToServe[${index}]`, row.costToServe);
      formData.append(`offInvoice[${index}]`, row.offInvoice);
      formData.append(`bedsLeds[${index}]`, row.bedsLeds);
      formData.append(`sugroRetro[${index}]`, row.sugroRetro);
    });

    try {
      const response = await axios.post(
        `${config.api_url}/ws-purchases-add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
        );

      const result = response.data;
      Swal.fire({
        title: "Success!",
        text: "Order submitted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      navigateToGRNList("/GRN");
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  const handleDiscClick = (id) => {
    setProductId(id);
    let data = tableRows.find(item => item.id === id);
    if (data.bedsLeds || data.costToServe || data.offInvoice || data.retro || data.sugroRetro || data.promptPayment) {
      setFormData({
        retro: data.retro || 0,
        promptPayment: data.promptPayment || 0,
        costToServe: data.costToServe || 0,
        offInvoice: data.offInvoice || 0,
        bedsLeds: data.bedsLeds || 0,
        sugroRetro: data.sugroRetro || 0,
      });
    }
    else {
      setFormData({
        retro: "",
        promptPayment: "",
        costToServe: "",
        offInvoice: "",
        bedsLeds: "",
        sugroRetro: "",
      });
    }
  };

  return (
    <div>
    <div className="modal fade" id="ON-PO" tabIndex="-1">
    <div className="modal-dialog modal-md">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">(SKIO11) SKITTLES FRUITS</h5>
    <button
    type="button"
    className="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    ></button>
    </div>
    <div className="modal-body">
    {onso.map((order) => (
      <div className="partialbox">
      <h3>
      S.O: {order.reference_no} <span>{order.company_name} ({order.code})</span>
      </h3>
      <table className="table table-bordered dataTable">
      <thead>
      <tr>
      <th>Assign</th>
      <th>Order Qty</th>
      <th>On P.O</th>
      <th>Balance</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      <td>
      <input
      className=""
      type="radio"
      name="gridRadios"
      value="option1"
      defaultChecked
      />
      </td>
      <td>{order.quantity}</td>
      <td>{order.po_qty}</td>
      <td>{order.po_qty - order.quantity}</td>
      </tr>
      </tbody>
      </table>
      </div>
      ))}

      </div>
      </div>
      </div>
      </div>

      <div className="modal fade" id="adnw-prct" tabIndex="-1">
      <div className="modal-dialog modal-lg">
      <div className="modal-content">
      <div className="modal-header">
      <h5 className="modal-title">&nbsp;</h5>
      <button
      type="button"
      className="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      ></button>
      </div>
      <div className="modal-body">
      <div className="partialbox">
      <h3>Search (Code/Description/Unit Barcode)</h3>
      <table className="table table-bordered dataTable">
      <thead>
      <tr>
      <th>Code</th>
      <th>Description</th>
      <th>PK</th>
      <th>Size</th>
      <th>Unit Barcode</th>
      <th>Stock</th>
      <th>F.Stk</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      <td>
      <a href="#" target="blank">
      SKI010
      </a>
      </td>
      <td>SKITTLES CRAZY SOURS</td>
      <td>36</td>
      <td>45GM</td>
      <td>&nbsp;</td>
      <td>324</td>
      <td>176</td>
      </tr>

      <tr>
      <td>
      <a href="#" target="blank">
      SKI010
      </a>
      </td>
      <td>SKITTLES CRAZY SOURS</td>
      <td>36</td>
      <td>45GM</td>
      <td>&nbsp;</td>
      <td>324</td>
      <td>176</td>
      </tr>
      <tr>
      <td>
      <a href="#" target="blank">
      SKI010
      </a>
      </td>
      <td>SKITTLES CRAZY SOURS</td>
      <td>36</td>
      <td>45GM</td>
      <td>&nbsp;</td>
      <td>324</td>
      <td>176</td>
      </tr>

      <tr>
      <td>
      <a href="#" target="blank">
      SKI010
      </a>
      </td>
      <td>SKITTLES CRAZY SOURS</td>
      <td>36</td>
      <td>45GM</td>
      <td>&nbsp;</td>
      <td>324</td>
      <td>176</td>
      </tr>
      </tbody>
      </table>
      </div>
      </div>
      </div>
      </div>
      </div>
      <ProductBatch
      onViewPastPurchase={(showModal) => {
        productBatchRef.current = showModal;
      }}
      getProductBatchData={getProductBatchData}
      modalData={modalData}
      />

      <div className="modal fade" id="viw-disk" tabIndex="-1" ref={discModal}>
      <div className="modal-dialog modal-sm">
      <div className="modal-content">
      <div className="modal-header">
      <h6 className="modal-title">&nbsp; Add Discount</h6>
      <button
      type="button"
      className="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      ></button>
      </div>
      <div className="modal-body">
      <div className="adddiscount-popup">
      <form
      onSubmit={(e) => handleSubmit(e, tableRows, productId)}
      id="addDiscountForm"
      >
      <table className="table table-bordered dataTable">
      <tbody>
      <tr>
      <td>Retro</td>
      <td>
      <input
      type="text"
      className="form-control"
      name="retro"
      value={formData.retro}
      onChange={handleDiscount}
      autoComplete="off"
      />
      </td>
      </tr>
      <tr>
      <td>Prompt Payment</td>
      <td>
      <input
      type="text"
      className="form-control"
      name="promptPayment"
      value={formData.promptPayment}
      onChange={handleDiscount}
      autoComplete="off"
      />
      </td>
      </tr>
      <tr>
      <td>Cost to Serve</td>
      <td>
      <input
      type="text"
      className="form-control"
      name="costToServe"
      value={formData.costToServe}
      onChange={handleDiscount}
      autoComplete="off"
      />
      </td>
      </tr>
      <tr>
      <td>Off Invoice</td>
      <td>
      <input
      type="text"
      className="form-control"
      name="offInvoice"
      value={formData.offInvoice}
      onChange={handleDiscount}
      autoComplete="off"
      />
      </td>
      </tr>
      <tr>
      <td>Beds & Leds</td>
      <td>
      <input
      type="text"
      className="form-control"
      name="bedsLeds"
      value={formData.bedsLeds}
      onChange={handleDiscount}
      autoComplete="off"
      />
      </td>
      </tr>
      <tr>
      <td>Sugro Retro</td>
      <td>
      <input
      type="text"
      className="form-control"
      name="sugroRetro"
      value={formData.sugroRetro}
      onChange={handleDiscount}
      autoComplete="off"
      />
      </td>
      </tr>
      </tbody>
      </table>
      <button
      className="popup-innerbtn-common"
      type="submit"
      style={{ marginTop: "10px" }}>
      Submit
      </button>
      </form>
      </div>
      </div>
      </div>
      </div>
      </div>


      <Header />
      <ViewProduct />
      <form id="newPurchaseForm">
      <div className="breadcrumbs">
      <div className="container-fluid">
      <div className="row">
      <div className="col-12 col-lg-12 col-sm-12">
      {" "}
      <a href="#">
      <i className="fa fa-home"></i>
      </a>{" "}
      &gt; <a href="dashboard">Dashboard</a> &gt;{" "}
      <span className="last-crumb">New Purchase Order</span>{" "}
      </div>
      </div>
      </div>
      </div>
      <div className="main-container">
      <div className="container-fluid">
      <div className="row">
      <div className="col-12 col-lg-12 col-sm-12">
      <div className="dashboard">
      <div className="">
      <div className="dashform">
      <div className="topsearch-area">
      <div className="row">
      <div className="col-12 col-lg-3 col-sm-3">
      <div className="row">
      <label
      htmlFor="inputText"
      className="col-sm-4 col-form-label"
      >
      Supplier:
      </label>
      <div className="col-sm-8">
      <SupplierSearch />
      </div>
      </div>
      </div>
      <div className="col-12 col-lg-3 col-sm-3">
      <div className="row">
      <label
      htmlFor="inputText"
      className="col-sm-4 col-form-label"
      >
      Product:
      </label>
      <div className="col-sm-8">
      <Select
      isClearable
      onInputChange={handleProductChange}
      onChange={handleChange}
      options={productData}
      placeholder="Select Product"
      value={productInputValue}
      />
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div className="verical-scrl-wrapper common-table purchase-no-tbl" id="style-3" >
      <table className="table table-bordered dataTable" id="dsTable" >
      <thead>
      <tr>
      <th>Code</th>
      <th>Description</th>
      <th>Pk</th>
      <th>Size</th>
      <th>Qty</th>
      <th>CPrice</th>
      <th>Value</th>
      <th>Disc</th>
      <th>NetP</th>
      <th>VAT</th>
      <th>CurStock</th>
      <th>On S.O</th>
      <th>FStk</th>
      <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      {tableRows.length === 0 ? (
        <tr style={{ textAlign: "center" }}>
        <td colSpan={14}>No product added</td>
        </tr>
        ) : (
        tableRows.map((row) => {

          const net_price = parseFloat(row.netp) || 0;

        //  console.log('net_price'+net_price);
          const value = parseFloat(row.value) || 0;
          const retro = parseFloat(row.retro) || 0;
          const promptPayment = parseFloat(row.promptPayment) || 0;
          const costToServe = parseFloat(row.costToServe) || 0;
          const offInvoice = parseFloat(row.offInvoice) || 0;
          const bedsLeds = parseFloat(row.bedsLeds) || 0;
          const sugroRetro = parseFloat(row.sugroRetro) || 0;
          const vat = parseFloat(row.vat) || 0;
          const totalCosts = promptPayment + costToServe + offInvoice + bedsLeds + sugroRetro + retro;

          const vatAmount = (((row.quantity * row.cost) / 100) * vat).toFixed(2);
          const fstk_cal = (Number(row.sys_quantity) || 0) + (Number(row.free_stock) || 0);



          return (
            <tr key={row.id}>
            <td>
            <input type="hidden" name="product_id[]" value={row.id} />
            <input type="hidden" name="code[]" value={row.code} />
            <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              getProductBatchData(row.id);
            }}
            >
            {row.code}
            </Link>
            </td>
            <td>
            <p className="cut-text">
            <a data-bs-toggle="modal"
            className="applybtn"
            data-id={row.id}
            data-bs-target="#viewproduct" >
            {row.product_desc}
            </a>
            </p>
            </td>
            <td>
            <input
            type="hidden"
            name="packsize[]"
            value={row.pack}
            />{" "}
            {row.pack}
            </td>
            <td>
            <input
            type="hidden"
            name="productSize[]"
            value={row.size}
            />{" "}
            {row.size}
            </td>
            <td>
            <input
            type="text"
            name="qty[]"
            className="form-control qtyinputbox"
            autoComplete="off"
            onChange={(e) =>
              handleInputChange(e, row.id, "quantity", setTableRows)
            }
            />
            </td>
            <td>
            <input
            type="text"
            name="cost[]"
            className="form-control qtyinputbox"
            value={row.cost}
            autoComplete="off"
            onChange={(e) =>
              handleInputChange_cprice(e, row.id, "cost", setTableRows)
            }
            />
            </td>
            <td>
            <input hidden type="text" name="value[]" className="form-control qtyinputbox"
            value={value.toFixed(2)} readOnly />
            {value.toFixed(2)}
            </td>
            <td>
            <input
            hidden
            type="text"
            name="disc[]"
            value={totalCosts.toFixed(2)}
            />{" "}
            <a
            href="#"
            data-id={row.id}
            onClick={() => handleDiscClick(row.id)}
            data-bs-toggle="modal"
            data-bs-target="#viw-disk"
            >
            {totalCosts.toFixed(2)}
            </a>
            </td>
            <td>
            <input
            type="hidden"
            name="netprice[]"
            value={row.netprice}
            />
             <input
            type="hidden"
            name="NetP[]"
            value={row.netp}
            />
            {row.netprice}
            </td>
            <td>
            <input
            type="hidden"
            name="vat[]"
            value={vatAmount}
            />
            <input
            type="hidden"
            name="vatPercentage[]"
            value={vat}
            />

            {vatAmount}
            </td>
            <td>
            <input type="hidden" name="currrentStock[]" />
            {row.sys_quantity}
            </td>
            <td>
            <input type="hidden" name="onSO[]" value="0" />
            {row.sale_qty !== 0 ? (
              
               <span> {row.sale_qty}</span>
            
              ) : (
              <span>{row.sale_qty}</span>
              )}
              </td>

              <td>
              <input
              type="hidden"
              name="fstk[]"
              value="0"
              />

             {row.fstk == 0 ? fstk_cal : row.fstk}
              </td>
              <td>
              <a
              href="#"
              value="Remove"
              onClick={() =>
                removeRow(row.id, setTableRows)
              } >
              <i className="fa fa-trash"></i>
              </a>
              </td>
              </tr>
              );
            })
            )}
            </tbody>
            </table>
            </div>
            <div className="purchaseftr">
            <div className="row">
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx">
            {" "}
            Std Goods :{" "}
            <span id="std_goods">{stdGoods.toFixed(2)}</span>
            </p>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx">
            {" "}
            VAT Amount :{" "}
            <span id="vat_amt">{vatAmt.toFixed(2)}</span>
            </p>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx">
            {" "}
            Zero Goods :{" "}
            <span id="zero_goods">{zeroGoods.toFixed(2)}</span>
            </p>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx last">
            {" "}
            Gross Total :{" "}
            <span id="grand_total">
            {grandTotal.toFixed(2)}
            </span>
            </p>
            </div>
            </div>            
            </div>

            <div className="purchaseftr">
            <div className="row">
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx">
            {" "}
            Net Std Goods :{" "}
            <span id="std_goods">{netStdGoods.toFixed(2)}</span>
            </p>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx">
            {" "}
            VAT Amount :{" "}
            <span id="vat_amt">{netVatAmt.toFixed(2)}</span>
            </p>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx">
            {" "}
            Zero Goods :{" "}
            <span id="zero_goods">{netZeroGoods.toFixed(2)}</span>
            </p>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
            <p className="frtbx last">
            {" "}
           Net Gross Total :{" "}
            <span id="grand_total">
            {netGrandTotal.toFixed(2)}
            </span>
            </p>
            </div>
            </div>            
            </div>

            <div className="purchaseform">
            <div className="row mb-3 padd-l-13px">
            <div className="col-6 col-lg-6 col-sm-6">
            <div className="row mb-3">
            <div className="col-3 col-lg-3 col-sm-3">
            <label htmlFor="desc" className="col-form-label">
            Date of Arrival
            </label>
            </div>

            <div className="col-3 col-lg-3 col-sm-3">
            <input
            type="date"
            className="form-control"
            name="arrival_date"
            id="arrival_date"
            />
            </div>

            <div className="col-3 col-lg-3 col-sm-3">
            <label htmlFor="desc" className="col-form-label padd-left-50">
            Job Ref 
            </label>
            </div>

            <div className="col-3 col-lg-3 col-sm-3">
            <input
            type="text"
            className="form-control"
            name="job_ref"
            id="job_ref"
            
            />
            </div>
            </div>


            <div className="row mb-3">
            <div className="col-3 col-lg-3 col-sm-3">

            <label   className="col-form-label padd-left-50">
            Delivery
            </label>
            </div>

            <div className="col-3 col-lg-3 col-sm-3">

            <input
            type="radio"
            name="delivery_type"
            id="delivery_type"
            value="delivery"
             defaultChecked
            onChange={(e) =>handleDeliveryandCollectionAddress(e)}  style={{ float: "left" }}
            />
            </div>

            <div className="col-3 col-lg-3 col-sm-3">

            <label htmlFor="collection" className="col-form-label padd-left-50">
            Collection
            </label>
            </div>

            <div className="col-3 col-lg-3 col-sm-3">
            <input
            type="radio"
            name="delivery_type"
            id="collection_type"
            value="collection"
            onChange={(e) =>handleDeliveryandCollectionAddress(e)} 
            />
            </div>
            </div>
            {  (isDelivery === 'delivery' || isDelivery === 'customer' || isDelivery === 'warehouse') && (
              <div className="row mb-3">
              <div className="col-3 col-lg-3 col-sm-3">

              <label htmlFor="desc" className="col-form-label padd-left-50">
              Warehouse Address
              </label>
              </div>

              <div className="col-3 col-lg-3 col-sm-3">
              <input class=""  onChange={handleWarehouseandCustomerAddress}  defaultChecked type="radio"  name="handleWarehouseandCustomerAddress" id="handleWarehouseandCustomerAddress" value="warehouse" />
              </div>

              <div className="col-3 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label padd-left-50">
              Customer Address
              </label>
              </div>

              <div className="col-3 col-lg-3 col-sm-3">
              <input class="" onChange={handleWarehouseandCustomerAddress} type="radio" name="handleWarehouseandCustomerAddress" id="handleWarehouseandCustomerAddress" value="customer" />
              </div>
              </div>
              )}

            <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
            <label htmlFor="desc" className="col-form-label">
            Notes
            </label>
            </div>
            <div className="col-6 col-lg-9 col-sm-9">
            <textarea
            className="form-control"
            name="notes"
            id="notes"
            style={{ height: "200px" }}
            ></textarea>
            </div>
            </div>
            </div>

   {(isDelivery==='delivery' || isDelivery === 'warehouse' ||  isDelivery === 'customer') && (
          <div className="col-6 col-lg-6 col-sm-6">
  {(isDelivery === 'warehouse' || isDelivery==='delivery' ) && (
            <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
            <label htmlFor="desc" className="col-form-label">
            Delivery Address *
            </label>
            </div>


            <div className="col-6 col-lg-9 col-sm-9">
            <select
            name="delivery_id"
            id="delivery_id" className="form-select orgselect"
            onChange={(event) =>
              changeWarehouseAddress(
              event,
              setDeliveryAddress,
              warehouseDeliveryAddress
              )
            }
            >
            <option value="">Select Address</option>
            {warehouseDeliveryAddress.map((item, index) => (
              <option key={index} value={item.id}>
              {item.name}
              </option>
              ))}
              </select>
              </div>
              </div>
)}


  {isDelivery === 'customer' && (
    <>
        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="desc" className="col-form-label">
                    Customer Address *
                </label>
            </div>
            <div className="col-6 col-lg-9 col-sm-9">
                <select
                    name="delivery_id"
                    id="delivery_id"
                    className="form-select orgselect"
                    onChange={(event) =>
                        changeCustomerAddress(event, setCustomerAddress, customerDeliveryAddress)
                    }
                >
                    <option>Select Address</option>
                    {customerDeliveryAddress.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.address_line1}
                        </option>
                    ))}
                </select>
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="addressLine1" className="col-form-label">
                    Address Line 1 
                </label>
            </div>
            <div className="col-6 col-lg-9 col-sm-9">
                <input
                    type="text"
                    className="form-control"
                    name="addressLine1"
                    id="addressLine1"
                    value={customerAddress.address_line1}
                    disabled
                />
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="addressLine2" className="col-form-label">
                    Address Line 2
                </label>
            </div>
            <div className="col-6 col-lg-9 col-sm-9">
                <input
                    type="text"
                    className="form-control"
                    name="addressLine2"
                    id="addressLine2"
                    value={customerAddress.address_line2}
                    disabled
                />
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="city" className="col-form-label">
                    City
                </label>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <input
                    type="text"
                    className="form-control"
                    name="city"
                    id="city"
                    value={customerAddress.city}
                    disabled
                />
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="state" className="col-form-label padd-left-50">
                    State
                </label>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <input
                    type="text"
                    className="form-control"
                    name="state"
                    id="state"
                    value={customerAddress.state}
                    disabled
                />
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="country" className="col-form-label">
                    Country
                </label>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <input
                    type="text"
                    className="form-control"
                    name="country"
                    id="country"
                    value={customerAddress.country}
                    disabled
                />
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="postCode" className="col-form-label padd-left-50">
                    Post Code
                </label>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <input
                    type="text"
                    className="form-control"
                    name="postCode"
                    id="postCode"
                    value={customerAddress.postcode}
                    disabled
                />
            </div>
        </div>
    </>
)}



{(isDelivery === 'warehouse' || isDelivery === 'delivery') && (
    <>
        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="name" className="col-form-label">
                    Warehouse Name
                </label>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    value={deliveryAddress.name}
                    disabled
                />
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="code" className="col-form-label padd-left-50">
                    Code
                </label>
            </div>
            <div className="col-6 col-lg-3 col-sm-3">
                <input
                    type="text"
                    className="form-control"
                    name="code"
                    id="code"
                    value={deliveryAddress.code}
                    disabled
                />
            </div>
        </div>

        <div className="row mb-3">
            <div className="col-6 col-lg-3 col-sm-3">
                <label htmlFor="addressLine1" className="col-form-label">
                    Address Line 1 
                </label>
            </div>
            <div className="col-6 col-lg-9 col-sm-9">
                <input
                    type="text"
                    className="form-control"
                    name="addressLine1"
                    id="addressLine1"
                    value={deliveryAddress.address_line1}
                    disabled
                />
            </div>
        </div>

        <div className="row mb-3">
   <div className="col-6 col-lg-3 col-sm-3">
      <label htmlFor="desc" className="col-form-label">
      Address Line 2
      </label>
   </div>
   <div className="col-6 col-lg-9 col-sm-9">
      <input
         type="text"
         className="form-control"
         name="addressLine2"
         id="addressLine2"
         value={deliveryAddress.address_line2}
         disabled
         />
   </div>
</div>
<div className="row mb-3">
   <div className="col-6 col-lg-3 col-sm-3">
      <label htmlFor="desc" className="col-form-label">
      City
      </label>
   </div>
   <div className="col-6 col-lg-3 col-sm-3">
      <input
         type="text"
         className="form-control"
         name="city"
         id="city"
         value={deliveryAddress.city}
         disabled
         />
   </div>
   <div className="col-6 col-lg-3 col-sm-3">
      <label htmlFor="desc" className="col-form-label padd-left-50">
      State
      </label>
   </div>
   <div className="col-6 col-lg-3 col-sm-3">
      <input
         type="text"
         className="form-control"
         name="state"
         id="state"
         value={deliveryAddress.state}
         disabled
         />
   </div>
</div>
<div className="row mb-3">
   <div className="col-6 col-lg-3 col-sm-3">
      <label htmlFor="desc" className="col-form-label">
      Country
      </label>
   </div>
   <div className="col-6 col-lg-3 col-sm-3">
      <input
         type="text"
         className="form-control"
         name="country"
         id="country"
         value={deliveryAddress.country}
         disabled
         />
   </div>
   <div className="col-6 col-lg-3 col-sm-3">
      <label htmlFor="desc" className="col-form-label padd-left-50">
      Post Code
      </label>
   </div>
   <div className="col-6 col-lg-3 col-sm-3">
      <input
         type="text"
         className="form-control"
         name="postCode"
         id="postCode"
         value={deliveryAddress.postcode}
         disabled
         />
   </div>
</div>
    </>
)}



</div>



               )}
 {isDelivery==='collection' && (


    <div className="col-6 col-lg-6 col-sm-6">

              <div className="row mb-3">                 

              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label padd-left-50">
              Collection Address
              </label>
              </div>
              <div className="col-6 col-lg-3 col-sm-3">
             
              </div>
              </div>
              <div className="row mb-3">
              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label">
              Address Line 1
              </label>
              </div>
              <div className="col-6 col-lg-9 col-sm-9">
              <input
              type="text"
              className="form-control"
              name="addressLine1"
              id="addressLine1"
              
             
              />
              </div>
              </div>
              <div className="row mb-3">
              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label">
              Address Line 2
              </label>
              </div>
              <div className="col-6 col-lg-9 col-sm-9">
              <input
              type="text"
              className="form-control"
              name="addressLine2"
              id="addressLine2"
            
              
              />
              </div>
              </div>
              <div className="row mb-3">
              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label">
              City
              </label>
              </div>
              <div className="col-6 col-lg-3 col-sm-3">
              <input
              type="text"
              className="form-control"
              name="city"
              id="city"
             
              
              />
              </div>

              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label padd-left-50">
              State
              </label>
              </div>
              <div className="col-6 col-lg-3 col-sm-3">
              <input
              type="text"
              className="form-control"
              name="state"
              id="state"
              
              />
              </div>
              </div>

              <div className="row mb-3">
              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label">
              Country
              </label>
              </div>
              <div className="col-6 col-lg-3 col-sm-3">
              <input
              type="text"
              className="form-control"
              name="country"
              id="country"
           
              />
              </div>
              <div className="col-6 col-lg-3 col-sm-3">
              <label htmlFor="desc" className="col-form-label padd-left-50">
              Post Code
              </label>
              </div>
              <div className="col-6 col-lg-3 col-sm-3">
              <input
              type="text"
              className="form-control"
              name="postCode"
              id="postCode"
           
              />
              </div>
              </div>

              </div>

 )}


              <input
              type="hidden"
              name="std_goods"
              value={stdGoods}
              />
              <input
              type="hidden"
              name="zero_goods"
              value={zeroGoods}
              />
              <input
              type="hidden"
              name="vat_amount"
              value={vatAmt}
              />
              <input
              type="hidden"
              name="grand_total"
              value={grandTotal}
              />




              <input
              type="hidden"
              name="net_std_goods"
              value={netStdGoods}
              />
              <input
              type="hidden"
              name="net_zero_goods"
              value={netZeroGoods}
              />
              <input
              type="hidden"
              name="net_vat_amount"
              value={netVatAmt}
              />
              <input
              type="hidden"
              name="net_grand_total"
              value={netGrandTotal}
              />


              <div className="col-6 col-lg-12 col-sm-12">
              <hr />
              </div>

              <div className="col-6 col-lg-12 col-sm-12">
              <Link
              to="#"
              className="popup-innerbtn-common right"
              onClick={submitPurchaseOrderForm} id="submitPurchaseOrderForm"
              >
              Place Purchase Order
              </Link>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </form>
              <Footer />
              </div>
              );
            };

            export default NewPurchaseOrder;
